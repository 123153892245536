import React, { useState, useEffect } from "react";
import axios from 'axios';

import Grid from './components/Grid.js';
import NovaColuna from './components/NovaColuna.js';
import NovoCard from './components/Novocard.js';
import EditarCartao from './components/EditarCartao.js';
import GridQuadroProprios from './components/GridQuadrosProprios.js';
import GridQuadroTerceiro from './components/GridQuadrosTerceiro.js';
import NovoQuadro from './components/Novoquadro.js';
import Compartilhar from './components/Compartilhar.js';
import NovaTarefa from './components/NovaTarefa.js';
import InfTarefa from './components/InfTarefa.js';
import MoverCartao from './components/MoverCartao.js';

import { useColunasHook } from '../../../hook/getColunas.js';
import { useCardHook } from '../../../hook/getCard.js';
import { useUsuariosHook } from '../../../hook/getUsuarios.js';
import { useQuadroHook } from '../../../hook/getQuadro.js';
import { useVisualizadorHook } from '../../../hook/getVisualizadorGerenciamento.js';
import { useCheckboxHook } from "../../../hook/getCheckboxGerenciamento.js";

import GlobalStyle from "../../../styles/global2.js";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { toast, ToastContainer } from "react-toastify";
import { IoMdArrowBack } from "react-icons/io";
import { IoClose } from "react-icons/io5";

import { MdOutlineFilterFrames } from "react-icons/md";


const Geral = styled.div` 
  width: 100vw;
  background-color: #373737;
`;

const DivGrid = styled.div` 
  height: 100vh;
`;
const Div = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  height: 4.0rem;
  background-color: #076633; 
`;

const Title2 = styled.h2`
  display: flex;
  justify-content: space-between; 
  align-items: center;
  width: 55%;
  color: #fff;
  font-size: 28px;


`;


const ButtonContainer = styled.div`
  display: flex;
  justify-content: start; 
`;

const ButtonClose = styled.button`
  cursor: pointer;
  border: none;
  background-color: #515151;
  transition: transform 1.5s;
  &:hover {
    transform: scale(1.5);
  }
`;


const ButtonSair = styled.button`
  padding: 20px 20px 0px 20px;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  background-color: transparent;
  color: white;
  height: 45px;
  font-weight: bold;
  font-size: 18px;
  margin: 5px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.2);
  }

`;
const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  
`;
const ModalContent = styled.div`
  background-color: #515151;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-height: 90vh; 
  overflow-y: auto; 

  

`;
const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 10px ;
  cursor: pointer;
  border: none;
  gap: 5px;
  background-color: transparent;
  color: white;
  font-size: 18px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.2);
  }

`;

function App() {

  //---------------------------------------------------------------------------------------------------------------//
  //Ip's da api e do front
  //const urlLogin = "/login";
  //const urlFront = "http://192.168.100.103:3050";
  //const urlApi = "http://192.168.100.103:8850/api";
  const urlFront = "";
  const urlApi = "/api";
  const urlLogin = "/";

  //---------------------------------------------------------------------------------------------------------------//

  //Token da api
  const token = localStorage.getItem('token');
  const id = parseInt(localStorage.getItem('id'));

  //---------------------------------------------------------------------------------------------------------------//

  //Verifica o token do usuário
  const [tToken, setToken] = useState("");
  const getToken = async () => {
    try {
      const res = await axios.get(`${urlApi}/login/token`, { headers: { Authorization: `Bearer ${token}` } });
      setToken(res.data);
    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}/login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }

  };

  useEffect(() => {
    getToken();

  }, []);

  //---------------------------------------------------------------------------------------------------------------//
  //Botão de sair e voltar


  const handleVoltar = () => {

    const url = `${urlLogin}`;
    window.location.href = url;
  };

  const handleQuadro = () => {
    setVerQuadro(null);
  };

  //---------------------------------------------------------------------------------------------------------------//


  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalNovaColuna = () => {
    setIsModalOpen(!isModalOpen);

  };

  //Abre o modal 'Novo cartão'
  const [novoCard, setNovoCard] = useState(false); //Recebe o valor da coluna, passa para o NovoCard criar o cartão
  const [isModalOpenCard, setIsModalOpenCard] = useState(false);
  const modalNovoCard = (coluna) => {
    setIsModalOpenCard(!isModalOpenCard);
    setNovoCard(coluna)
  };

  //Abre o modal 'Editar cardo'
  const [editarCartao, setEditarCartao] = useState(false);
  const [isModalOpenEditarCartao, setIsModalOpenEditarCartao] = useState(false);
  const modalEditarCartao = (card) => {
    setIsModalOpenEditarCartao(!isModalOpenEditarCartao); // Corrigido para alternar o estado corretamente
    setEditarCartao(card); // Atualiza o card para editar, ou null para limpar ao fechar
    fetchCard();
  };

  //Abre o modal 'Novo quadro'
  const [isModalOpenQuadro, setIsModalOpenQuadro] = useState(false);
  const modalNovoQuadro = () => {
    setIsModalOpenQuadro(!isModalOpenQuadro);

  };

  //Abre o modal 'Compartilhar'
  const [isModalOpenCompartilhar, setIsModalOpenCompartilhar] = useState(false);
  const modalCompartilhar = () => {
    setIsModalOpenCompartilhar(!isModalOpenCompartilhar);
  };

  //Abre nova tarefa
  const [isModalOpenTarefa, setIsModalOpenTarefa] = useState(false);
  const modalNovaTarefa = () => {
    setIsModalOpenTarefa(!isModalOpenTarefa);
  };

  //modal mover tarefa
  const [isModalOpenMover, setIsModalOpenMover] = useState(false);
  const modalMover = () => {
    setIsModalOpenMover(!isModalOpenMover); // Corrigido para alternar o estado corretamente

  };

  //---------------------------------------------------------------------------------------------------------------//
  //Custons hooks
  const { colunas, fetchColunas } = useColunasHook();
  const { card, fetchCard } = useCardHook();
  const { usuarios } = useUsuariosHook();
  const { quadro, fetchQuadro } = useQuadroHook();
  const { visualizador, fetchVisualizador } = useVisualizadorHook();
  const { checkbox, fetchCheckbox } = useCheckboxHook();

  //---------------------------------------------------------------------------------------------------------------//
  //State responsavel por mostrar o quadro que o usuário escolheu
  const [verQuadro, setVerQuadro] = useState(null);

  
  
  //---------------------------------------------------------------------------------------------------------------//
  // Verifica quem criou o chamado para liberar o botões
  const [criador, setCriador] = useState(null)
  useEffect(() => {
    if (verQuadro) {
      const criador = verQuadro.id_criador === id;
      setCriador(criador);
      
   
      if (id === verQuadro.id_criador) {
        const viewer = visualizador.find(v => 
          v.id_usuario === verQuadro.id_criador && 
          v.id_quadro === verQuadro.id_quadro
        );
           
        if (viewer) {
          
        } else {
          handleVisualizador();
          
        }
      } else {
        
      }
    }
    

  }, [verQuadro]);

    //Insere o criador do quadro como visualizador
  const handleVisualizador = async () => {
    
    
    
    const data = {
      id_quadro: verQuadro.id_quadro,
      id_usuario: id,
    };
  
    try {
      await axios.post(`${urlApi}/visualizador-gerenciamento`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchVisualizador(); // Atualiza os visualizadores após a adição
    } catch (error) {
      console.error('Erro ao adicionar visualizador:', error);
    }
  };

  //Modal respondendo a tarefa
  //Abre o modal 'Informações da tarefa'
  const [infTarefa, setInfTarefa] = useState("")
  const [isModalOpenInfTarefa, setIsModalOpenInfTarefa] = useState(false);
  const modalInfTarefa = (check) => {
    setIsModalOpenInfTarefa(!isModalOpenInfTarefa);
    setInfTarefa(check)
  };


  
  return (
    <>
      {isModalOpenQuadro && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => {
                modalNovoQuadro();
              }}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <NovoQuadro urlApi={urlApi} modalNovoQuadro={modalNovoQuadro} fetchQuadro={fetchQuadro} />
          </ModalContent>
        </ModalContainer>
      )}

      {isModalOpen && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => {
                modalNovaColuna();
              }}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>

            <NovaColuna urlApi={urlApi} modalNovaColuna={modalNovaColuna} fetchColunas={fetchColunas} verQuadro={verQuadro} />
          </ModalContent>
        </ModalContainer>
      )}

      {isModalOpenCard && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => {
                modalNovoCard();
              }}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <NovoCard urlApi={urlApi} modalNovoCard={modalNovoCard} novoCard={novoCard} fetchCard={fetchCard} usuarios={usuarios}  visualizador={visualizador} quadro={quadro}/>
          </ModalContent>
        </ModalContainer>
      )}

      {isModalOpenEditarCartao && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => modalEditarCartao()}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <EditarCartao
              urlApi={urlApi}
              card={card}
              modalEditarCartao={modalEditarCartao}
              fetchCard={fetchCard}
              usuarios={usuarios}
              editarCartao={editarCartao}
              checkbox={checkbox}
              fetchCheckbox={fetchCheckbox}
              modalNovaTarefa={modalNovaTarefa}
              modalInfTarefa={modalInfTarefa}
              infTarefa={infTarefa}
              modalMover={modalMover}
            />
          </ModalContent>
        </ModalContainer>
      )}

      {isModalOpenCompartilhar && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => {
                modalCompartilhar();
              }}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <Compartilhar urlApi={urlApi} usuarios={usuarios} verQuadro={verQuadro} visualizador={visualizador} fetchVisualizador={fetchVisualizador} />
          </ModalContent>
        </ModalContainer>
      )}

      {isModalOpenTarefa && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => {
                modalNovaTarefa();
              }}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <NovaTarefa urlApi={urlApi}
              modalEditarCartao={modalEditarCartao}
              fetchCard={fetchCard}
              usuarios={usuarios}
              editarCartao={editarCartao}
              checkbox={checkbox}
              fetchCheckbox={fetchCheckbox}
              modalNovaTarefa={modalNovaTarefa}
              visualizador={visualizador} />
          </ModalContent>
        </ModalContainer>
      )}

      {isModalOpenInfTarefa && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => {
                modalInfTarefa();
              }}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <InfTarefa urlApi={urlApi} usuarios={usuarios} visualizador={visualizador} checkbox={checkbox} fetchCheckbox={fetchCheckbox} modalInfTarefa={modalInfTarefa} infTarefa={infTarefa} card={card} />
          </ModalContent>
        </ModalContainer>
      )}

      {isModalOpenMover && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => modalMover()}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <MoverCartao
              urlApi={urlApi}
              modalEditarCartao={modalEditarCartao}
              editarCartao={editarCartao}
              colunas={colunas}
              fetchColunas={fetchColunas}
              modalMover={modalMover}
              
            />
          </ModalContent>
        </ModalContainer>
      )}
      <Geral>
        <Title>

          <Title2>

            <Div>
              <ButtonSair onClick={handleVoltar} title="Voltar">
                <IoMdArrowBack />
              </ButtonSair>
              <Div>
                <h2>Projetos</h2>
              </Div>
            </Div>


            <Button onClick={handleQuadro} title="Ver os quadros">
              <MdOutlineFilterFrames /> Quadros
            </Button>

          </Title2>


        </Title>
        <DivGrid>
          {verQuadro ? (
            <Grid
              urlApi={urlApi}
              modalNovaColuna={modalNovaColuna}
              colunas={colunas}
              card={card}
              modalNovoQuadro={modalNovoQuadro}
              fetchCard={fetchCard}
              fetchColunas={fetchColunas}
              modalNovoCard={modalNovoCard}
              modalEditarCartao={modalEditarCartao}
              verQuadro={verQuadro}
              modalCompartilhar={modalCompartilhar}
              criador={criador}
            />
          ) : (
            <div>
              <GridQuadroProprios
                urlApi={urlApi}
                quadro={quadro}
                modalNovoQuadro={modalNovoQuadro}
                fetchQuadro={fetchQuadro}
                setVerQuadro={setVerQuadro}
              />

              <GridQuadroTerceiro
                urlApi={urlApi}
                quadro={quadro}
                modalNovoQuadro={modalNovoQuadro}
                fetchQuadro={fetchQuadro}
                setVerQuadro={setVerQuadro}
                visualizador={visualizador}
                usuarios={usuarios}
              />
            </div>
          )}
        </DivGrid>
        <GlobalStyle />
      </Geral >

      <ToastContainer autoClose={2500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
    </>
  );
}

export default App;
