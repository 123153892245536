import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import DashboardPortal from './components/portal/dashboard-portal/Dashboard.js';
import Usuarios_portal from './components/portal/usuarios-portal/Usuarios.js';

import DashboardIndicadores from './components/gestao/dashboard-indicadores/Dashboard.js';
import Login from './components/login/Login.js';
import Usuarios from './components/gestao/usuarios-indicadores/Usuarios.js';
import Equipamentos_servico from './components/gestao/modulo-indicadores/Modulo.js';
import Acessos from './components/gestao/acessos-indicadores/Acessos.js';

import Dashboard_gerenciamento from './components/projetos/dashboard-gerenciamento-projetos/Dashboard.js';

import Dashboard_boletins from './components/boletins/dashboard-boletins/Dashboard.js';

import Treinamento from './components/treinamento/Treinamento.js';

function App() {
    return (
        <Router>

            <Routes>
                <Route path="/login"   element={<Login />}  />

                <Route exact path="/"   element={<DashboardPortal />} />
                <Route path="/cadastro-usuarios"   element={<Usuarios_portal />}  />


                <Route path="/gestao/"   element={<DashboardIndicadores />} />
                <Route path="/gestao/cadastro-usuarios"   element={<Usuarios />}  />
                <Route path="/gestao/cadastro-url"   element={<Equipamentos_servico />}  />
                <Route path="/gestao/acessos"   element={<Acessos />}  />

                <Route path="/projetos/"   element={<Dashboard_gerenciamento />} />       

                <Route path="/boletins/"   element={<Dashboard_boletins />} />     

                <Route path="/treinamento/"   element={<Treinamento />} />   
            </Routes>
        </Router>

    )
}
export default App;

