import React from 'react';
import './Sidebar.css';
import logo from './Logomarca Cooperval-05.png';
import styled from "styled-components";
import { ImExit } from "react-icons/im";


const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh; /* Ocupa toda a altura da tela */
  overflow: hidden; /* Evita rolagem extra */
  gap: 25px;
`;

const BackgroundImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1; 
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row; 
  justify-content: center;
  align-items: center;
  gap: 30px; 
  z-index: 1; 
`;

const Button = styled.button`
  width: 250px;
  height: 90px;
  padding: 10px 20px;
  font-size: 24px;
  cursor: pointer;
  color: white; /* Cor do texto */
  background: rgb(7, 102, 51); /* Fundo verde translúcido */
  border: 1px solid rgb(7, 102, 51); /* Borda verde translúcida */
  border-radius: 10px; /* Bordas arredondadas */
  backdrop-filter: blur(10px); /* Efeito de desfoque */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s, border 0.3s;

  &:hover {
    transform: scale(1.2);
    background: rgba(7, 102, 51); /* Fundo verde um pouco mais opaco no hover */
    border: 1px solid rgba(7, 102, 51, 0.1); /* Borda verde mais opaca no hover */
  }
`;

const ButtonDesat = styled.button`
  width: 250px;
  height: 90px;
  padding: 10px 20px;
  font-size: 24px;
  cursor: pointer;
  color: white; /* Cor do texto */
  background: gray; /* Fundo verde translúcido */
  border: 1px solid gray; /* Borda verde translúcida */
  border-radius: 10px; /* Bordas arredondadas */
  backdrop-filter: blur(10px); /* Efeito de desfoque */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s, border 0.3s;

 
`;


const Button2 = styled.button`
  width: 250px;
  height: 90px;
  padding: 10px 20px;
  top: 2;
  left: 0;
  font-size: 24px;
  cursor: pointer;
  color:  rgb(7, 102, 51); /* Cor do texto */
  border: 1px solid rgb(7, 102, 51); /* Borda verde translúcida */
  border-radius: 10px; /* Bordas arredondadas */
  backdrop-filter: blur(10px); /* Efeito de desfoque */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s, border 0.3s;

  &:hover {
    transform: scale(1.2);
    
  }


`;

const ButtonSide1 = styled.button`
  cursor: pointer;
  border: none;
  background-color: #f5f5f5;
  color: black;
  font-size: 22px;


  &.button-exit{
    position: absolute;
    top: 0;
    right: 0;
    padding: 1vw;
  }

`;
const Grid = ({ tipoUsuarioIndicadores, urlFront, tipoUsuarioPortal, urlLogin }) => {


  const handleIndicadores = () => {
    const url = `/gestao/`;
    window.location.href = url;
  };
  const handleBoletins = () => {
    const url = `/boletins/`;
    window.location.href = url;
  };


  const handleGerenciar = () => {
    const url = `${urlFront}/cadastro-usuarios`;
    window.location.href = url;
  };

  const handleGerenciamento = () => {
    const url = `/projetos/`;
    window.location.href = url;
  };

  const handleTreinamento = () => {
    const url = `/treinamento/`;
    window.location.href = url;
  };

  const handleExit = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('usuario');

    const url = `${urlLogin}`;
    window.location.href = url;
  };
  return (
    <Container>
      <ButtonSide1 className="button-exit" onClick={handleExit} title={"Sair"}>
        <ImExit />
      </ButtonSide1>
      <BackgroundImage src={logo} style={{ width: '300px', height: '70px', padding: '0px 20px 20px 0px' }} alt="Logo" />
      <ContentWrapper>


        {(tipoUsuarioIndicadores === 'ADMIN' || tipoUsuarioIndicadores === 'USER' || tipoUsuarioIndicadores === 'GESTOR' || tipoUsuarioIndicadores === 'TREINADOR') && (
          <Button onClick={handleIndicadores}>Gestão</Button>
        )}

        <>

          {(tipoUsuarioPortal === 'ADMIN' || tipoUsuarioPortal === 'GESTOR') ? (
            <Button onClick={handleBoletins}>Boletins Web</Button>
          ) : (
            <ButtonDesat>Boletins Web</ButtonDesat>
          )}

        </>
        
        <>

        {(tipoUsuarioPortal === 'ADMIN' || tipoUsuarioPortal === 'GESTOR') ? (
            <Button onClick={handleGerenciamento}>Painel de Monitoramento</Button>
          ) : (
            <ButtonDesat>Painel de Monitoramento</ButtonDesat>
          )}

        </>

        <>

          {(tipoUsuarioPortal === 'ADMIN' || tipoUsuarioPortal === 'GESTOR') ? (
            <Button onClick={handleTreinamento}>Treinamentos</Button>
          ) : (
            <ButtonDesat>Treinamentos</ButtonDesat>
          )}

        </>



      </ContentWrapper>
      <ContentWrapper>

        <ButtonDesat >Controle de Frota</ButtonDesat>
        <ButtonDesat >Metas</ButtonDesat>
        <ButtonDesat >Estoque Mínimo</ButtonDesat>
        <ButtonDesat >Segurança Patrimonial</ButtonDesat>

      </ContentWrapper>
      <ContentWrapper>

        <ButtonDesat >Segurança do Trabalho</ButtonDesat>
        <ButtonDesat >Procedimentos</ButtonDesat>
        <ButtonDesat >Ocorrências</ButtonDesat>
        {(tipoUsuarioPortal === 'ADMIN') && (
          <Button2 onClick={handleGerenciar} type="button"> Gerenciar Usuários </Button2>
        )}

      </ContentWrapper>

    </Container >


  );
};

export default Grid;
