import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";



const FormContainer = styled.form`
  width: auto;
  background-color: #515151;
  display: flex; 
  align-items: flex-start;
  flex-direction: row;

`;

const DivContainer = styled.form`
  width: auto;
  background-color: #515151;
  display: flex; 
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 0px 0px 20px 0px 
`;

const Div = styled.div`
  width:95%;
  background-color: #515151;
  display: flex; 
  padding: 10px 10px;
  align-items: flex-end;
  gap: 20px 0px;
  flex-direction: row;
  margin-top: 10px;
`;



const InputArea = styled.div`
  width: 100%;
  height: auto; 
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;

  select {
    width: 120px;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 1px;
    background-color: #5d5d5d;
  }

`;



const Hr = styled.div`
  display: flex;
  align-items: center; /* Alinha ao centro verticalmente */
  width: 95%; /* Largura total */
  height: 1px; /* Altura da linha */
  background-color: #ccc; /* Cor da linha */
  justify-content: center;
  margin-left: 2%;
`;


const InputTitle = styled.textarea`
  color: #fff;
  width: 100%; 
  min-height: 100px; 
  border: 1px solid #ccc;
  border-radius: 1px;
  padding: 10px;
  background-color: #5d5d5d;
  font-size: 20px;
  resize: none; 
  overflow: hidden; 
  box-sizing: border-box; 
  white-space: pre-wrap;
`;





export const Td = styled.td`
  font-size: 14px;
  word-break: break-word;
  overflow-wrap: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  white-space: normal;
  max-width: 400px; /* Limita a largura máxima */
  overflow: hidden;
  text-overflow: ellipsis; /* Para adicionar "..." se necessário */
`;

const Table = styled.table`
  width: 800px;
  background-color: #515151;
  padding: 5px 20px;
  color: #fff;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 05px; 
`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
`;

export const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
`;

const Checkbox = styled.input`
  margin-right: 8px; /* Espaço à direita do checkbox */
`;

const Form = ({ urlApi, usuarios, verQuadro, visualizador, fetchVisualizador }) => {
  const ref = useRef();


  useEffect(() => {
    if (verQuadro) {
      const user = ref.current;

      user.titulo_quadro.value = verQuadro.titulo_quadro;
    }

  }, [verQuadro]);


  //Token da api
  const token = localStorage.getItem('token');
  const id = parseInt(localStorage.getItem('id'));







  const [selectedUsers, setSelectedUsers] = useState({});

  useEffect(() => {
    if (!visualizador) return;
    // Inicializa o estado selectedUsers com base nos dados do visualizador
    const initialSelectedUsers = {};
    visualizador.forEach((v) => {
      if (v.id_quadro === verQuadro.id_quadro) {
        initialSelectedUsers[v.id_usuario] = true;
      }
    });
    setSelectedUsers(initialSelectedUsers);
  }, [visualizador, verQuadro.id_quadro]);



  //fornece o id para selecionar o email
  const [emailRecebe, setEmailRecebe] = useState()


  const handleCheckbox = (id_usuario) => {
    const isSelected = selectedUsers[id_usuario];

    setSelectedUsers((prev) => ({
      ...prev,
      [id_usuario]: !isSelected,
    }));


    if (!isSelected) {
      // Adicionar ao banco de dados
      const data = {
        id_quadro: verQuadro.id_quadro,
        id_usuario: id_usuario,
      };


      const usuarioEmail2 = usuarios.find(user => user.id_usuario === data.id_usuario);
      const emailRecebe2 = usuarioEmail2 ? usuarioEmail2.email : 'Usuário não encontrado';

      setEmailRecebe(emailRecebe2)
      axios
        .post(`${urlApi}/visualizador-gerenciamento`, data, { headers: { Authorization: `Bearer ${token}` } })
        .then(() => {
          fetchVisualizador(); // Atualiza os visualizadores após a adição



          enviarEmail(emailRecebe2);

        })
        .catch((error) => {
          console.error('Erro ao adicionar visualizador:', error);
        });

    } else {
      // Remover do banco de dados
      const visualizadorToDelete = visualizador.find(
        (v) => v.id_usuario === id_usuario && v.id_quadro === verQuadro.id_quadro
      );

      if (visualizadorToDelete) {
        axios
          .delete(`${urlApi}/visualizador-gerenciamento/${visualizadorToDelete.id_visualizador}`, { headers: { Authorization: `Bearer ${token}` } })
          .then(() => {
            fetchVisualizador(); // Atualiza os visualizadores após a exclusão
          })
          .catch((error) => {
            console.error('Erro ao remover visualizador:', error);
          });
      }
    }
  };




  //Renderiza o login do criador
  const usuarioCriador = usuarios.find(user => user.id_usuario === verQuadro.id_criador);
  const loginCriador = usuarioCriador ? usuarioCriador.login : '';

  const enviarEmail = (email) => {
    axios.get(`${urlApi}/email-gerenciamento/visualizador`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { userTo: email, criador: loginCriador, titulo: verQuadro.titulo_quadro }
    })
      .then(response => {
        console.log("E-mail enviado com sucesso:", response.data.message);
      })
      .catch(error => {
        if (error.response) {
          console.error("Status:", error.response.status);
          console.error("Headers:", JSON.stringify(error.response.headers, null, 2));
          console.error("Data:", error.response.data);
        } else if (error.request) {
          console.error("Request não respondida:", error.request);
        } else {
          console.error("Erro na configuração da requisição:", error.message);
        }
        console.error("Configuração completa:", error.config);
      });
  };

  const usuariosVisualizadores = usuarios
    .filter(user => user.id_usuario !== id);
  return (
    <div >
      <FormContainer  >
        <DivContainer ref={ref} >


          <Div>
            <InputArea>
              <InputTitle
                name="titulo_quadro"
                disabled
              />
            </InputArea>
          </Div>

          <Div>
            <Table>
              <Tbody>
                {usuariosVisualizadores.map((usuario) => (
                  <div key={usuario.id_usuario}>
                    <Checkbox
                      type="checkbox"
                      onChange={() => handleCheckbox(usuario.id_usuario)}
                      checked={!!selectedUsers[usuario.id_usuario]}
                    />
                    {usuario.login} - {usuario.nome}
                  </div>
                ))}
              </Tbody>
            </Table>
          </Div>


        </DivContainer>
      </FormContainer>
      <style jsx global>{`
      .swal2-container.custom-swal-container {
        z-index: 9999; 
      }
    `}</style>
    </div>

  );
};

export default Form;


