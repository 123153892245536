import { useState, useEffect } from 'react';
import axios from 'axios';

//const url = "http://192.168.100.103:8850/api";
const url = "/api";
const token = localStorage.getItem('token');


export const useCardHook = () => {
    const [card, setCard] = useState([]);


    const fetchCard = async () => {
        try {
            const res = await axios.get(`${url}/gerenciamento-card`, { headers: { Authorization: `Bearer ${token}` } });
            setCard(res.data);
        } catch (error) {
            console.error('Erro ao buscar dados de card:', error);
        }
    };


    useEffect(() => {
        fetchCard();
    }, [token]);

    
    return { card, fetchCard };
};
