import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';



export const useDebouncedPutTitle = (urlApi, id_card, field, token, editarCartao) => {
  const [isFocused, setIsFocused] = useState(false);
  const [fieldValue, setFieldValue] = useState(editarCartao.titulo_card);



  const debouncedPutRequest = useCallback(
    debounce((newValue) => {
      axios
        .put(
          `${urlApi}/gerenciamento-card/cartao-title/${id_card}`,
          { [field]: newValue },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log(`${field} atualizado com sucesso:`, response.data);
        })
        .catch((error) => {
          console.error(`Erro ao atualizar o ${field}:`, error);
        });

    }), // 500ms de debounce
    []
  );

  const handleChange = (event) => {
    const newValue = event.target.value;
    setFieldValue(newValue);
  };

  const handleBlur = () => {
    debouncedPutRequest(fieldValue);
    setIsFocused(false);
  };

  useEffect(() => {
    return () => {
      debouncedPutRequest.cancel();
    };
  }, []);

  return {
    isFocused,
    setIsFocused,
    fieldValue,
    setFieldValue,
    handleChange,
    handleBlur,
  };
};
