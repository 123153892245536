import React, { useState, useEffect } from "react";
import axios from "axios"

import GridTreinamento from "./components/Grid-treinamento.js";
import VerUsuario from "./components/VerUsuario.js";

import GlobalStyle from "../../styles/global.js";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../styles/imagem/Logomarca-06.png";
import { toast, ToastContainer } from "react-toastify";
import { ImExit } from "react-icons/im";
import { IoClose } from "react-icons/io5";
import { IoMdArrowBack } from "react-icons/io";

const DivLayout = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  box-sizing: border-box;
`;

const Title = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 5.5rem;
  background-color: #076633;
`;

const Title2 = styled.h2`
  width: 100%;
  color: #fff;
  font-size: 28px;
  display: flex;

  align-items: flex-start;


`;



const Div = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0px 0px 0px;


  span{
    padding: 0px  10px;
  }
`;








const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  
`;
const ModalContent = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-height: 90vh; 
  overflow-y: auto; 

  

`;
const ButtonClose = styled.button`
  cursor: pointer;
  border: none;
  background-color: #f5f5f5;
  transition: transform 1.5s;
  &:hover {
    transform: scale(1.5);
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: start; 
`;



const DivCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  span{
    padding: 0px  10px;
  }
`;

const ButtonSair = styled.button`
  padding: 20px 20px 0px 20px;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  background-color: transparent;
  color: white;
  height: 45px;
  font-weight: bold;
  font-size: 18px;
  margin: 5px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.2);
  }

`;

function Usuarios() {

  //const urlFront = "http://192.168.100.103:3050/indicadores/";
  //const urlApi = "http://192.168.100.103:8850/api";
  //const urlLogin = "http://192.168.100.103:3050/";
  const urlFront = "/indicadores/";
  const urlApi = "/api";
  const urlLogin = "/";

  const token = localStorage.getItem('token');




  const [onEdit, setOnEdit] = useState(null);





  //Links
  const handleVoltar = () => {

    const url = `${urlLogin}`;
    window.location.href = url;
  };




  //Verifica o token do usuário
  const [tToken, setToken] = useState("");
  const getToken = async () => {
    try {
      const res = await axios.get(`${urlApi}/login/token`, { headers: { Authorization: `Bearer ${token}` } });
      setToken(res.data);
    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}/login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getToken();
    }, 300000);


    return () => clearInterval(intervalId);
  }, [])




  //Abre o modal 'Ver Usuario'
  const [isModalOpentoggleModalUsuarios, setIsModalOpentoggleModalUsuarios] = useState(false);
  const toggleModalUsuarios = () => {
    setIsModalOpentoggleModalUsuarios(!isModalOpentoggleModalUsuarios);

  };

  const [filtered2, setFiltered2] = useState();
  // Função para buscar eventos
  const getEventos2 = async () => {
    try {
      const res = await axios.get(`${urlApi}/treinamento/eventos2`, {
        headers: { Authorization: `Bearer ${token}` }
      });


      const sortedEventos = res.data.sort((a, b) => {
        return b[1] - a[1]; // Ordenando pelo segundo valor (decrescente)
      });

      const eventosFuncio = sortedEventos.map(item => ({
        id: item[0],
        eventoId: item[1],
        titulo: item[2],
        data: item[3],
        status: item[4],
        qtdPrevista: item[5],
        qtdParticipantes: item[6],
        faltantes: item[7],
        imprevistos: item[8]
      }));

      setFiltered2(eventosFuncio); // Certifique-se de que res.data seja um array
    } catch (err) {
      console.log("Conexão falhou", err);
    }
  };

  useEffect(() => {
    getEventos2();

  }, []);

  return (
    <>



      {isModalOpentoggleModalUsuarios && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => {
                toggleModalUsuarios();
              }}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <VerUsuario onEdit={onEdit} urlApi={urlApi}   />
          </ModalContent>
        </ModalContainer>
      )}

      <div >
        <DivLayout>
          <Title>
            <div>
              <ButtonSair onClick={handleVoltar} title="Voltar">
                <IoMdArrowBack />
              </ButtonSair>
            </div>
            <Title2>
              <div>
                <h2>Treinamentos</h2>
              </div>




            </Title2>
            
          </Title>


        </DivLayout>

        <Container>
          <DivCenter>

            <GridTreinamento setOnEdit={setOnEdit} urlApi={urlApi} toggleModalUsuarios={toggleModalUsuarios} filtered2={filtered2} />


          </DivCenter>

        </Container>
        <Div>

          <ToastContainer autoClose={2500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
          <GlobalStyle />
        </Div>
      </div>
    </>
  );
}

export default Usuarios;
