import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import {  ImBin} from "react-icons/im";
import { TbArrowsMoveHorizontal } from "react-icons/tb";
import { FaPlus } from "react-icons/fa";
import { FaInfo } from "react-icons/fa";
import Swal from "sweetalert2";
import './style.css'
import { useDebouncedPutDescricao } from "./useDebouncedPutDescricao.js";
import { useDebouncedPutMeta } from "./useDebouncedPutMeta.js";
import { useDebouncedPutTitle } from "./useDebouncedPutTitle.js";
import { useDebouncedPutAnexo } from "./useDebouncedPutAnexo.js";



const FormContainer = styled.form`
  width: auto;
  background-color: #515151;
  display: flex; 
  align-items: flex-start;
  flex-direction: row;

`;

const DivContainer = styled.form`
  width: auto;
  background-color: #515151;
  display: flex; 
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #e8eaeb;
  padding: 10px;
`;

const Div = styled.div`
  width: 95%;
  background-color:#515151;
  display: flex; 
  padding: 10px 10px;
  align-items: flex-end;
  gap: 20px 0px;
  flex-direction: row;
 
`;


const DivButtons = styled.div`
  max-width:100%;
  background-color: #515151;
  display: flex; 
  padding: 5px 0px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;



const TextAreaContainer = styled.div`
  width: 100%;
  height: auto; 
  display: flex;
  flex-direction: column;
`;

const Hr = styled.div`
  display: flex;
  align-items: center; /* Alinha ao centro verticalmente */
  width: 95%; /* Largura total */
  height: 1px; /* Altura da linha */
  background-color: #fff; /* Cor da linha */
  justify-content: center;
  margin-left: 2%;
`;

const InputArea = styled.div`
  width: auto;
  height: auto; 
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;

  select {
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 1px;
    padding: 0px 10px;
    background-color: #5d5d5d;
    font-size: 16px;
  }
  
`;

const InputTitle = styled.textarea`
  width: 100%; 
  min-height: 100px; 
  border: 1px solid #ccc;
  border-radius: 1px;
  padding: 10px;
  background-color: #5d5d5d;
  font-size: 20px;
  resize: none; 
  overflow: hidden; 
  box-sizing: border-box; 
  white-space: pre-wrap; 
  color: #fff;
`;

const InputDescricao = styled.textarea`
  width: 700px; 
  height: 250px; 
  border: 1px solid #ccc;
  border-radius: 1px;
  padding: 10px 10px 00px 20px;
  color: #fff;
  background-color: #5d5d5d;
  resize: none; /* Desabilitar o redimensionamento manual */
  box-sizing: border-box; /* Incluir o padding e a borda na largura total */
  overflow: auto; /* Permitir a rolagem automática */
  white-space: pre-wrap; /* Manter quebras de linha e espaços */
`;



const Input = styled.input`
  width: 130px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 1px;
  padding: 0px 10px;
  background-color: #515151;
  font-size: 16px;
  color: #fff;
`;



const Label = styled.label`
  color: #fff;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  margin: 0px 5px;
  padding: 0px 0px 0px 10px; 


`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  width: 150px;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border: 1px solid #e8eaeb;
  background-color: #515151;
  height: 42px;
  font-weight: bold;
  margin: 5px;
  border-radius: 5px;
  transition: transform 0.1s;
  gap: 10px;
  background: rgba(255, 255, 255, 0.1); /* Fundo translúcido */
  border: 1px solid rgba(255, 255, 255, 0.9); /* Borda translúcida */
  border-radius: 10px; /* Bordas arredondadas */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s;
  color: #fff;
  &:hover {
    transform: scale(1.1);
  }
`;

const A = styled.a`
  color: #fff;
  text-decoration: none;
  padding: 0px 0px 0px 10px; 
  margin: 0px 0px 0px 10px; 
`;



const Tr = styled.tr`
  display: flex; 
  justify-content: space-between;
  text-align: center;
  padding-bottom: 05px;

`;

export const Td = styled.td`
  font-size: 14px;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  color: #fff;


`;

const Table = styled.table`
  width: 100%;
  background-color: #515151;
  padding: 5px 20px;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 05px; 
`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
`;


const TdButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  background-color: #515151;


  transition: transform 0.1s;



  &:hover {
    transform: scale(1.1);
  }
`;

const Checkbox = styled.input`
  margin-right: 8px; 
`;
function getCurrentDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');


  return `${year}-${month}-${day} ${hours}:${minutes}`;
}
const Form = ({ editarCartao, modalInfTarefa, urlApi, usuarios, checkbox, fetchCheckbox, modalNovaTarefa,  modalMover }) => {

  //Token da api
  const token = localStorage.getItem('token');
  const id = parseInt(localStorage.getItem('id'));

  //---------------------------------------------------------------------------//
  //Recebe os valores para editar, tipo onEdit
  const ref = useRef();
  useEffect(() => {
    if (editarCartao) {
      const user = ref.current;

      user.id_criador.value = editarCartao.id_criador;
      user.id_responsavel.value = editarCartao.id_responsavel;
      user.titulo_card.value = editarCartao.titulo_card;
      user.descricao_card.value = editarCartao.descricao_card;
      user.data_criacao.value = editarCartao.data_criacao;
      user.meta_conclusao.value = editarCartao.meta_conclusao;
      setImageUrl(editarCartao.id_card);
      setImageName(editarCartao.image);
    }

  }, [editarCartao]);




  const [imageName, setImageName] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageUrl2, setImageUrl2] = useState(null);

  useEffect(() => {
    fetch(`${urlApi}/upload-gerenciamento/img/${imageUrl}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        if (response.ok) {
          setImageUrl2(response.url);
          console.log(response)
        } else {
          console.error('Erro ao obter a imagem:', response.statusText);
          console.log(response)
        }
      })
      .catch((error) => {
        console.error('Erro na requisição:', error);
      });
  }, [imageUrl]);

  //---------------------------------------------------------------------------//
  //Salva os inputs automaticamente
  const { isFocused, setIsFocused, fieldValue: descricaoAcao, handleChange: handleDescricaoChange, handleBlur } =
    useDebouncedPutDescricao(urlApi, editarCartao.id_card, 'descricao_card', token, editarCartao);

  const { handleChange: handleEntregaChange, handleBlur: handleEntregaBlur, isFocused: isFocusedEntrega, setIsFocused: setIsFocusedEntrega } =
    useDebouncedPutMeta(urlApi, editarCartao.id_card, 'meta_conclusao', token, editarCartao);

  const { isFocused: isFocusedTitulo, setIsFocused: setIsFocusedTitulo, fieldValue: tituloCard, handleChange: handleTituloChange, handleBlur: handleTituloBlur
  } = useDebouncedPutTitle(urlApi, editarCartao.id_card, 'titulo_card', token, editarCartao);

  const { isFocused: isFocusedAnexo, setIsFocused: setIsFocusedAnexo, fieldValue: anexoCard, handleChange: handleAnexoChange, handleBlur: handleAnexoBlur
  } = useDebouncedPutAnexo(urlApi, editarCartao.id_card, 'image', token, editarCartao);

  //---------------------------------------------------------------------------//

  const handleTarefa = () => {
    modalNovaTarefa();
  };

  const handleMover = () => {
    modalMover();
  };

  //---------------------------------------------------------------------------//
  //Renderiza o login do criador
  const usuarioCriador = usuarios.find(user => user.id_usuario === editarCartao.id_criador);
  const loginCriador = usuarioCriador ? usuarioCriador.login : '';
  //Renderiza o login do responsavel
  const usuarioResponsavel = usuarios.find(user => user.id_usuario === editarCartao.id_responsavel);
  const loginResponsavel = usuarioResponsavel ? usuarioResponsavel.login : '';



  //---------------------------------------------------------------------------//
  //Filtra as tarefas
  const checkFiltrados = checkbox.filter(v => v.id_card === editarCartao.id_card);



  //---------------------------------------------------------------------------//
  //Delete as tarefas
  const handleDelete = async (check) => {

    const userConfirmed = await showPopUp();

    if (!userConfirmed) {
      return;
    }


    try {
      // Tenta realizar a requisição para deletar o usuário
      const { data } = await axios.delete(`${urlApi}/gerenciamento-checkbox/${check.id_check}`, {
        headers: { Authorization: `Bearer ${token}` },
      });


      toast.success("Tarefa deletada com sucesso.");



    } catch (error) {
      // Tratamento de erros
      console.error('Erro ao deletar o usuário:', error);

      let errorMessage = 'Erro desconhecido ao deletar.';
      if (error.response) {
        errorMessage = error.response.data || errorMessage;
        console.log('Erro na resposta:', error.response);
      } else if (error.request) {
        console.log('Erro na requisição:', error.request);
      } else {
        console.log('Erro na configuração da requisição:', error.message);
      }

      toast.error(errorMessage);

      // Cria log de erro na exclusão

    }
    fetchCheckbox();
  };


  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Deseja confirmar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container', // Estiliza o container principal
          title: 'custom-swal-title', // Estiliza o título
          confirmButton: 'custom-swal-confirm-button', // Estiliza o botão de confirmação
          cancelButton: 'custom-swal-cancel-button', // Estiliza o botão de cancelamento
          popup: 'custom-swal-popup', // Estiliza o popup
          icon: 'custom-swal-icon' // Estiliza o ícone
        },
      }).then((result) => {
        resolve(result.value);
      });
    });
  };


  const handleCheckboxChange = async (id_check, isChecked) => {
    // Se estiver marcado, desmarcar e enviar null; senão, enviar a data atual
    const dataConclusao = isChecked ? null : getCurrentDateTime();

    const respondido = checkbox.find(user => user.id_check === id_check);
    if (respondido.resposta_check === null) {
      toast.error("Favor responda a tarefa, antes de concluir.");
      return;
    }

    try {
      const response = await axios.put(
        `${urlApi}/gerenciamento-checkbox/${id_check}`,
        { data_conclusao: dataConclusao },
        { headers: { Authorization: `Bearer ${token}` } }
      );


      fetchCheckbox(); // Atualiza a lista de checkboxes no frontend
    } catch (error) {
      console.error("Erro ao atualizar checkbox:", error);
      toast.error("Erro ao atualizar a tarefa.");
    }
  };

  //Informações da tarefa
  const handleInfTarefa = (check) => {
    modalInfTarefa(check);
  };





  const [message, setMensagem] = useState('');
  const [nameArquivo, setNameArquivo] = useState('');
  const uploadImageOnChange = async (e) => {
    const selectedImage = e.target.files[0];

    const formData = new FormData();
    formData.append('image', selectedImage);
    setNameArquivo(selectedImage.name);
    const headers = {
      'headers': {
        'Content-Type': 'multipart/form-data'
      }
    }

    try {
      const response = await axios.post(`${urlApi}/upload-gerenciamento/upload-image`, formData, headers);
      setMensagem(response.data.message);
    } catch (err) {
      if (err.response) {
        setMensagem(err.response.data.message);
      } else {
        setMensagem("Erro: verifique o tipo de arquivo que está sendo anexado")
      }
    }
  };

  return (
    <div >
      <FormContainer>
        <DivContainer ref={ref} >

          <TextAreaContainer >
            <InputTitle
              id="titulo_card"
              type="text"
              value={tituloCard}  // Bind do valor ao estado
              onChange={handleTituloChange}
              onFocus={() => setIsFocusedTitulo(true)}
              onBlur={handleTituloBlur}
              className={isFocused ? 'textarea-focused' : ''}
              disabled={id !== editarCartao.id_criador}  // Desabilita se não for o criador
            />

          </TextAreaContainer>

          <Div>

            <InputArea>
              <Label>Criador:</Label>
              <Input
                name="id_criador"
                value={loginCriador}
                readOnly
                disabled
              />
            </InputArea>

            <InputArea>
              <Label>Responsavel:</Label>
              <Input
                name="id_responsavel"
                value={loginResponsavel}
                readOnly
                disabled
              />
            </InputArea>



          </Div>
          <Div>

            <InputArea>
              <Label>Criado:</Label>
              <Input
                name="data_criacao"
                readOnly
                disabled

              />
            </InputArea>
            <InputArea >
              <Label>Entrega:</Label>
              <Input
                name="meta_conclusao"
                type="date"
                onChange={handleEntregaChange}
                onFocus={() => setIsFocusedEntrega(true)}
                onBlur={handleEntregaBlur}
                className={isFocused ? 'textarea-focused' : ''}
                disabled={id !== editarCartao.id_criador}  // Desabilita se não for o criador
              />
            </InputArea>


          </Div>


          <Hr />


          <Div>
            <TextAreaContainer>
              <Label>Descrição:</Label>
              <InputDescricao
                onChange={handleDescricaoChange}
                className={isFocused ? 'textarea-focused' : ''}
                onFocus={() => setIsFocused(true)}
                id="descricao_card"
                name="msg"
                onBlur={handleBlur}
                disabled={id !== editarCartao.id_criador}  // Desabilita se não for o criador
              />
            </TextAreaContainer>
          </Div>



          <>
            <Div>
              <Table>
                <>
                  {checkFiltrados.length > 0 && (

                    <Tbody>
                      {checkFiltrados.map((check) => {
                        // Função find para buscar o login do responsável
                        const usuarioResponsavelTarefa = usuarios.find(user => user.id_usuario === check.id_responsavel_tarefa);
                        const loginResponsavelTarefa = usuarioResponsavelTarefa ? usuarioResponsavelTarefa.login : 'Responsável não encontrado';

                        return (
                          <Tr key={check.id_check}>
                            <Td width="75%" style={{ textAlign: 'left' }}>
                              {(id === check.id_responsavel_tarefa) && (
                                <Checkbox
                                  type="checkbox"
                                  checked={check.data_conclusao !== null}
                                  onChange={() => handleCheckboxChange(check.id_check, check.data_conclusao !== null)}
                                />
                              )}

                              {check.descricao_check}
                              <div style={{ marginTop: '5px', color: '#888', marginLeft: '50px' }}>
                                Responsável: {loginResponsavelTarefa} {/* Exibe o login do responsável */}
                              </div>
                            </Td>

                            <TdButton width="5%" onClick={() => handleInfTarefa(check)} style={{ textAlign: 'right' }} type='button'>
                              <FaInfo size={18} color="Gold" />
                            </TdButton>
                            <>
                              {(id === editarCartao.id_criador || id === editarCartao.id_responsavel) && (
                                <TdButton width="5%" onClick={() => handleDelete(check)} style={{ textAlign: 'right' }} type='button'>
                                  <ImBin size={18} color="Red" />
                                </TdButton>
                              )}
                            </>
                          </Tr>
                        );
                      })}
                    </Tbody>


                  )}
                </>

              </Table>
            </Div>
          </>

          <Hr />
          
          <Div>
            {imageUrl2 ? (
              <A href={imageUrl2} target="_blank" rel="noopener noreferrer">
                <p>Arquivo: {imageName}</p>
              </A>
            ) : (
              <A>Nenhum arquivo anexado.</A>
            )}

          </Div>
            
          <form>
            {id === editarCartao.id_criador && (  // Corrige a condicional para verificar se o usuário é o criador
              <>
                <Label>Anexo:</Label>
                {message ? <p>{message}</p> : ""}
                <input
                  type="file"
                  name="image"
                  onChange={(event) => {
                    uploadImageOnChange(event);  // Chama a função para salvar o arquivo no servidor
                    handleAnexoChange(event);    // Chama a função para salvar o nome da imagem no banco
                  }}
                  onFocus={() => setIsFocusedAnexo(true)}
                  onBlur={handleAnexoBlur}       // Chama a função de blur para o debounce
                  className={isFocusedAnexo ? 'input-focused' : ''}
                  disabled={id !== editarCartao.id_criador}  // Desabilita se não for o criador
                />

              </>
            )}
          </form>

        </DivContainer>

        <FormContainer>
          <DivButtons>

            <div style={{ display: 'flex', flexDirection: 'column' }}>

              {(id === editarCartao.id_criador || id === editarCartao.id_responsavel) && (
                <Button onClick={handleTarefa} type="button">
                  <FaPlus size={18} color={"gold"} /> CheckList
                </Button>
              )}

              {(id === editarCartao.id_criador ) && (
                <Button  onClick={handleMover} type="button">
                  <TbArrowsMoveHorizontal size={18} color={"blue"} /> Mover
                </Button>
              )}
            </div>

          </DivButtons>
        </FormContainer>
      </FormContainer>


      <style jsx global>{`
      .swal2-container.custom-swal-container {
        z-index: 9999; 
      }
    `}</style>
    </div >

  );
};

export default Form;


