import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ImArrowLeft, ImArrowRight } from "react-icons/im";

const Geral = styled.div`
  width: 99%;
  border: 1px solid #ccc;
  padding: 5px;
`;
const Table = styled.table`
  width: 99%;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 5px auto;

`;

export const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

export const Tr = styled.tr`
  text-align: center;
  text-decoration: none;
  border: none;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {

    transform: scale(0.99);
    color: black;
    font-weight: bold;
  }
}

`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;


`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 

`;

export const Td1 = styled.td`
  padding-top: 15px;
  text-align: center;


`;

export const Td2 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
`;

export const Td3 = styled.td`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  span{
    margin: 0px 5px;
  }

`;

const Input = styled.input`
  width: 345px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;


`;

const Button = styled.button`
  
  cursor: pointer;
  border: none;

  color: black;

  transition: transform 0.1s;
  &:hover {
    transform: scale(1.3);
  }
`;

const Div = styled.div`
display: flex;
align-items: center;

  
  span{
    padding: 0px  10px;
  }
`;



const Grid = ({ setOnEdit, toggleModalUsuarios, filtered2 }) => {


  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;






  const handleSearch = async (term) => {
    setIsLoading(true);

    try {
      const filtered = filtered2.filter((user) => {
        const columnsToSearch = [
          'titulo',
        ];

        // Verifica se algum dos valores nas colunas contém o termo de pesquisa
        return columnsToSearch.some((column) => {
          const columnValue = user[column] ? user[column].toLowerCase() : '';
          return columnValue.includes(term.toLowerCase());
        });
      });
      setFilteredUsers2(filtered);
    } catch (error) {
      console.error("Erro ao pesquisar:", error);
    }

    setIsLoading(false);
    // setSearchTerm('');
  };
  useEffect(() => {
    handleSearch(searchTerm);

  }, [searchTerm]);

  useEffect(() => {
    setFilteredUsers2(filtered2); 
  }, [filtered2]);


  const [filteredUsers2, setFilteredUsers2] = useState([]); // Inicializando como array vazio

  // Paginação
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  
  // Verifica se `filteredUsers2` é um array e faz o slice
  const currentItems2 = Array.isArray(filteredUsers2) ? filteredUsers2.slice(indexOfFirstItem, indexOfLastItem) : [];
  
  // Verifica se `filteredUsers2` existe e tem length, caso contrário assume 0
  const totalPages = filteredUsers2 && Array.isArray(filteredUsers2) ? Math.ceil(filteredUsers2.length / itemsPerPage) : 1;
  
  // Função para ir para a próxima página
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  
  // Função para ir para a página anterior
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };


  const handleEdit = (item) => {
    setOnEdit(item);
    toggleModalUsuarios();
  };



  return (

    <Geral>
      <Td3 colSpan="7">
        <Input
          type="text"
          placeholder="Pesquisar... Ex: titulo"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <Div>
          <div>
            <Button onClick={handlePrevPage} disabled={currentPage === 1}><ImArrowLeft size={20} /></Button>
            <span>Página <strong>{currentPage}</strong> de <strong>{totalPages}</strong></span>
            <Button onClick={handleNextPage} disabled={currentPage === totalPages}> <ImArrowRight size={20} /></Button>
          </div>
        </Div>
      </Td3>
      <Table >

        <Thead>
          <Tr>
            <Th>Nome do Treinamento:</Th>
            <Th>Data:</Th>
            <Th>Qtd. Prevista</Th>
            <Th>Qtd. Participante</Th>
            <Th>Faltante</Th>
            <Th>Imprevisto</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems2.map((item, i) => (
            <Tr key={i} onClick={() => handleEdit(item)}>
              <Td>{item.titulo}</Td> {/* Título do treinamento */}
              <Td>{item.data}</Td> {/* Data do treinamento */}
              <Td>{item.qtdPrevista}</Td> {/* Quantidade prevista */}
              <Td>{item.qtdParticipantes}</Td> {/* Quantidade participante */}
              <Td>{item.faltantes}</Td> {/* Quantidade faltantes */}
              <Td>{item.imprevistos}</Td> {/* Quantidade imprevista */}
            </Tr>
          ))}
        </Tbody>

      </Table>

    </Geral>
  );
};


export default Grid;
