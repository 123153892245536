import React from 'react';
import ReactApexChart from 'react-apexcharts';

const BarChart = ({ somandoUsuarios, onUserSelect }) => {


  const seriesData = Array.isArray(somandoUsuarios) ? somandoUsuarios.map(item => item.acessos) : [];
  const categoriesData = Array.isArray(somandoUsuarios) ? somandoUsuarios.map(item => item.login) : [];
  const idsData = Array.isArray(somandoUsuarios) ? somandoUsuarios.map(item => item.login) : []; // Obtenha os IDs dos módulos



  const options = {
    chart: {
      type: 'bar',
      height: categoriesData.length * 50,
      width: '100%',
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const selectedId = idsData[config.dataPointIndex]; // Obtenha o ID do módulo correspondente ao índice do ponto de dados selecionado
          onUserSelect(selectedId); // Chame a função de callback com o ID do módulo
        }
      },
      toolbar: {
        show: false  // Ocultando as ferramentas do gráfico
      }
    },
    colors: ['#04a44c'],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        barHeight: '70%',
      }
    },/*
    dataLabels: {
      enabled: true,  // Ativar rótulos dentro da barra
      style: {
        colors: ['#fff']  // Cor do texto dentro da barra
      },
      formatter: function (val, opts) {
        // Mostrar a descrição do módulo (ou qualquer outro valor desejado)
        return categoriesData[opts.dataPointIndex];  // Retorna a descrição do módulo
      }
    },*/
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: categoriesData, // use the module descriptions as categories
    }
  };

  const series = [{
    data: seriesData, // use the access data as the series
  }];

  return (
    <div style={{ overflowY: 'auto', maxHeight: '300px' }}>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="bar" height={categoriesData.length * 50} />
      </div>
    </div>
  );
};

export default BarChart;
