import React, { useState } from 'react';
import styled from 'styled-components';
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";

import { BsCardHeading } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import { TbColumnInsertLeft } from "react-icons/tb";
import { FaPeopleArrows } from "react-icons/fa";
import './style.css'
const Container = styled.div`
  display: flex;
  align-items: flex-start; 
  justify-content: flex-start; /* Alinha à esquerda para crescimento horizontal */
  flex-wrap: nowrap; /* Evita quebra de linha, permite crescimento horizontal */
  gap: 10px; 
  background-color: #373737;
  width: 100%;

  white-space: nowrap; /* Impede que o conteúdo quebre para a próxima linha */

  div {
    display: flex;
    flex-direction: row;
  }
`;


const Box = styled.div`
  flex: 0 0 300px; /* Define largura fixa de 300px */
`;

const DivBox = styled.div`
  width: 310px;
  max-height: 80vh;
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  text-align: start;
  box-sizing: border-box;
  margin: 5px;

  background: rgba(255, 255, 255, 0.1); /* Fundo translúcido */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Borda translúcida */
  border-radius: 10px; /* Bordas arredondadas */
  backdrop-filter: blur(10px); /* Efeito de desfoque */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s;

  h3 {
    
    color: #fff;
  }
  
  p {
    
    color: #4C4D4D;
  }

  
`;
const DivLista = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  
`;
const DivCabecario = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
  height: 30px;;

  h3 {
    white-space: normal; /* Permite quebras de linha automáticas */
    word-break: break-word; /* Quebra a palavra quando não cabe na linha */
  }
  
  div {
    display: flex;
    flex-direction: row;
    gap: 100px;
  }
`;

const ContainerColuna = styled.div`
  display: flex;
  align-items: flex-start; 
  justify-content: flex-start; /* Alinha à esquerda para crescimento horizontal */
  flex-wrap: nowrap; /* Evita quebra de linha, permite crescimento horizontal */
  padding: 10px; 
  gap: 10px; 
  background-color: #373737;
  width: 100%;
  min-height: 70px;
  max-height: calc(100vh - 120px);
  white-space: nowrap; /* Impede que o conteúdo quebre para a próxima linha */
  height: 100vh;
  
  /* Lógica de scroll horizontal aplicada */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap; /* Impede quebra de linha */

  white-space: nowrap; /* Impede que os itens quebrem para a próxima linha */

  position: fixed; /* Fixa o elemento em relação à janela do navegador */
  bottom: 0; /* Mantém o elemento na parte inferior da viewport */
  left: 0;
  right: 0;
  overflow-y: hidden;
`;
const Div = styled.div`
  display: flex;
  width: 250px;
  flex-direction: column;
  align-items: start;
  margin: auto;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  height: 80px;
  background-color: #f4f4f5;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1); /* Fundo translúcido */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Borda translúcida */
  border-radius: 10px; /* Bordas arredondadas */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s;

  transition: transform 0.2s;
  &:hover {
    transform: scale(1.01);
  }
  
 
  p {
    color: #fff;
    
    overflow-wrap: break-word; /* Quebra palavras longas */
    white-space: normal; /* Permite que o texto quebre em várias linhas */
  }
`;

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alinha o conteúdo ao início */
  justify-content: flex-start; /* Garante que o conteúdo fique no topo */
  white-space: normal; /* Permite quebra de linha no texto */
`;

const Divdata = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start; /* Mantém os itens no topo */
  width: 100%;
`;

const P = styled.p`
  color: #fff;
  overflow-wrap: break-word; /* Quebra palavras longas */
  white-space: normal; /* Permite que o texto quebre em várias linhas */
`;
const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 10px ;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 15px;
  gap: 5px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.2);
  }

`;

const Button2 = styled.button`
  display: flex;
  align-items: center;

  cursor: pointer;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 20px;

  transition: transform 0.3s;
  &:hover {
    transform: scale(1.2);
  }

`;
const Grid = ({ urlApi, colunas, card, modalNovoCard, fetchCard, fetchColunas, modalEditarCartao, modalNovaColuna, verQuadro, modalCompartilhar, criador }) => {

  const token = localStorage.getItem('token');
  const id = parseInt(localStorage.getItem('id'));





  //---------------------------------------------------------------------------------------------------------------//
  //Cria nova coluna
  const handleNovoCard = (coluna) => {
    modalNovoCard(coluna)
  };


  //---------------------------------------------------------------------------------------------------------------//
  //Deletar lista e cartão
  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: `Essa ação é irreversível, tem certeza que gostaria de continuar?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container', // Estiliza o container principal
          title: 'custom-swal-title', // Estiliza o título
          confirmButton: 'custom-swal-confirm-button', // Estiliza o botão de confirmação
          cancelButton: 'custom-swal-cancel-button', // Estiliza o botão de cancelamento
          popup: 'custom-swal-popup', // Estiliza o popup
          icon: 'custom-swal-icon' // Estiliza o ícone
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };

  const [excluir, setExcluir] = useState(false)
  const handleExcluir = async () => {
    setExcluir(true);
  };

  const handleExcluirLista = async (coluna) => {
    const userConfirmed = await showPopUp();
    if (!userConfirmed) {
      return;
    }
    try {
      // Tenta realizar a requisição para deletar o usuário
      const { data } = await axios.delete(`${urlApi}/gerenciamento/${coluna.id_coluna}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      /*
      const { data2 } = await axios.delete(`${urlApi}/delete-acesso/${item.id_modulo}/${item.id_pagina}`, {
        headers: { Authorization: `Bearer ${token}` },
      });*/


    } catch (error) {
      // Tratamento de erros
      console.error('Erro ao deletar o usuário:', error);

      let errorMessage = 'Erro desconhecido ao deletar.';
      if (error.response) {
        errorMessage = error.response.data || errorMessage;
        console.log('Erro na resposta:', error.response);
      } else if (error.request) {
        console.log('Erro na requisição:', error.request);
      } else {
        console.log('Erro na configuração da requisição:', error.message);
      }

      toast.error(errorMessage);

    }
    fetchColunas();
    setExcluir(false);

  };

  const handleExcluirCartao = async (card) => {
    const userConfirmed = await showPopUp();
    if (!userConfirmed) {
      return;
    }
    try {
      // Tenta realizar a requisição para deletar o usuário
      const { data } = await axios.delete(`${urlApi}/gerenciamento-card/${card.id_card}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      /*
      const { data2 } = await axios.delete(`${urlApi}/delete-acesso/${item.id_modulo}/${item.id_pagina}`, {
        headers: { Authorization: `Bearer ${token}` },
      });*/


    } catch (error) {
      // Tratamento de erros
      console.error('Erro ao deletar o usuário:', error);

      let errorMessage = 'Erro desconhecido ao deletar.';
      if (error.response) {
        errorMessage = error.response.data || errorMessage;
        console.log('Erro na resposta:', error.response);
      } else if (error.request) {
        console.log('Erro na requisição:', error.request);
      } else {
        console.log('Erro na configuração da requisição:', error.message);
      }

      toast.error(errorMessage);

    }

    fetchCard();
    setExcluir(false);

  };




  //---------------------------------------------------------------------------------------------------------------//
  //Editar cartão
  const handleEdit = (card) => {
    modalEditarCartao(card)
  };

  //Abre o modal 'Nova lista'
  const handleNovaColuna = () => {
    modalNovaColuna()
  };

  //Abre o compartilhar usuário
  const handleCompartilhar = () => {
    modalCompartilhar()
  };

  return (
    <div>
      <Container>
        {criador && (
          <div>
            <Button onClick={handleNovaColuna} title="Cria nova lista">
              <TbColumnInsertLeft /> Adicionar lista
            </Button>
            <Button onClick={handleCompartilhar} title="Compartilhar o quadro com outros usuários">
              <FaPeopleArrows /> Compartilhar
            </Button>
          </div>

        )};

        <Button >
          Quadro atual: <strong>{verQuadro.titulo_quadro}</strong>
        </Button>
      </Container>
      <ContainerColuna className='scroll'>
        {colunas
          .filter((coluna) => coluna.id_quadro === verQuadro.id_quadro)
          .map((coluna) => (
            <Box key={coluna.id_coluna} className="coluna" >
              <DivBox>
                <DivCabecario>
                  <h3>{coluna.descricao_coluna}</h3>
                  {(excluir && criador) && (
                    <Button onClick={() => handleExcluirLista(coluna)} title="Excluir" type='button'>
                      <IoCloseSharp />
                    </Button>
                  )}
                  
                </DivCabecario>

                {/* Filtrando e renderizando os cards que pertencem a essa coluna */}
                <DivLista className='scroll'>
                  {card
                    .filter((card) => card.id_coluna === coluna.id_coluna) // Filtra os cards com base no id_coluna
                    .map((card) => ( // Renderiza os cards filtrados
                      <Div key={card.id_card} className="card" onClick={() => handleEdit(card)} type="button">
                        <TextContainer >
                          <P>{card.titulo_card}</P>
                        </TextContainer>

                        <Divdata>
                          
                          <P>{card.meta_conclusao}</P>
                          {(excluir && criador) && (
                            <Button2
                              onClick={(event) => {
                                event.preventDefault(); // Impede o comportamento padrão
                                event.stopPropagation(); // Impede a propagação do evento para o Div pai
                                handleExcluirCartao(card);
                              }}
                              title="Excluir"
                              type="button"
                            >
                              <IoCloseSharp />
                            </Button2>
                          )}
                        </Divdata>

                      </Div>
                    ))}
                </DivLista>

                <DivCabecario>
                  {criador && (
                    <div>
                      <Button onClick={() => handleNovoCard(coluna)} title="Cria novo cartão">
                        <BsCardHeading /> Adicionar cartão
                      </Button>
                      {!excluir && (
                        <Button onClick={() => handleExcluir()} title="Excluir">
                          <IoCloseSharp />
                        </Button>
                      )}
                    </div>
                  )}
                </DivCabecario>

              </DivBox>

            </Box>

          ))}
      </ContainerColuna>

    </div>

  );
};

export default Grid;
