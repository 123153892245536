import React, { useState } from 'react';
import styled from 'styled-components';
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";
import './style.css';
import { BsCardHeading } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";


const Box = styled.div`
  width: 100vw;  

`;

const DivMaster = styled.div`
  height: auto; 
  width: 98%; 
  flex: 1;
  padding: 10px;

  box-sizing: border-box;
  margin: 5px;
  gap: 10px;
  background: rgba(255, 255, 255, 0.1); 
  border: 1px solid rgba(255, 255, 255, 0.3); 
  border-radius: 10px; 
  backdrop-filter: blur(10px); 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  transition: transform 0.1s, background 0.3s;

  /* Lógica de scroll horizontal aplicada */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap; /* Impede quebra de linha */
  overflow-x: auto; /* Habilita scroll horizontal */
  white-space: nowrap; /* Impede que os itens quebrem para a próxima linha */

  h3 {
    text-align: start;
    color: white;
  }
`;
const DivBox = styled.div`

  box-sizing: border-box;
  margin: 5px;
  display: flex;
  gap: 10px
`;
const DivCabecario = styled.div`
  display: flex;
  width: 20%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
  height: 30px;

  h3 {
    white-space: normal; /* Permite quebras de linha automáticas */
    word-break: break-word; /* Quebra a palavra quando não cabe na linha */
  }
`;

const ContainerColuna = styled.div`
  display: flex;
  align-items: flex-start; 
  justify-content: flex-start; /* Alinha à esquerda para crescimento horizontal */
  flex-wrap: nowrap; /* Evita quebra de linha, permite crescimento horizontal */
  padding: 10px; 
  gap: 10px; 
  background-color: #373737;
  width: 100%;
  height: auto;
  overflow-x: auto; /* Habilita rolagem horizontal */
  white-space: nowrap; /* Impede que o conteúdo quebre para a próxima linha */

`;
const Div = styled.div`
  width: 250px;
  height: 150px;
  display: flex;
  flex-direction: column;

  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f4f4f5;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  transition: transform 0.2s, background 0.3s;
  flex-shrink: 0; /* Garante que os itens não encolham */
  
  &:hover {
    transform: scale(1.03);
  }

`;
const Divdata = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end; /* Alinha o botão ao final */
  align-items: flex-start; /* Mantém o botão no início horizontal */
  width: 100%;
  margin-top: auto; /* Empurra o botão para o final da div */
`;


const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 10px ;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 15px;
  gap: 5px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.2);
  }

`;

const P = styled.p`
  color: #fff;
  overflow-wrap: break-word; /* Quebra palavras longas */
  white-space: normal; /* Permite que o texto quebre em várias linhas */
`;
const Grid = ({ urlApi, modalNovoQuadro, quadro, fetchQuadro, setVerQuadro }) => {

  const token = localStorage.getItem('token');
  const id = parseInt(localStorage.getItem('id'));





  //---------------------------------------------------------------------------------------------------------------//
  //Cria nova coluna
  const handleNovoQuadro = () => {
    modalNovoQuadro()
  };


  //---------------------------------------------------------------------------------------------------------------//
  //Deletar lista e cartão
  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: `Essa ação é irreversível, tem certeza que gostaria de continuar?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container', // Estiliza o container principal
          title: 'custom-swal-title', // Estiliza o título
          confirmButton: 'custom-swal-confirm-button', // Estiliza o botão de confirmação
          cancelButton: 'custom-swal-cancel-button', // Estiliza o botão de cancelamento
          popup: 'custom-swal-popup', // Estiliza o popup
          icon: 'custom-swal-icon' // Estiliza o ícone
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };

  const [excluir, setExcluir] = useState(false)
  const handleExcluir = async () => {
    setExcluir(true);
  };



  const handleExcluirCartao = async (quadro) => {
    const userConfirmed = await showPopUp();
    if (!userConfirmed) {
      return;
    }
    try {
      // Tenta realizar a requisição para deletar o usuário
      const { data } = await axios.delete(`${urlApi}/quadro/${quadro.id_quadro}`, {
        headers: { Authorization: `Bearer ${token}` },
      });



    } catch (error) {
      // Tratamento de erros
      console.error('Erro ao deletar o usuário:', error);

      let errorMessage = 'Erro desconhecido ao deletar.';
      if (error.response) {
        errorMessage = error.response.data || errorMessage;
        console.log('Erro na resposta:', error.response);
      } else if (error.request) {
        console.log('Erro na requisição:', error.request);
      } else {
        console.log('Erro na configuração da requisição:', error.message);
      }

      toast.error(errorMessage);

    }



    fetchQuadro();
    setExcluir(false);
  };




  //---------------------------------------------------------------------------------------------------------------//

  const showPopUp2 = (titulo) => {
    return new Promise((resolve) => {
      Swal.fire({
        title: `Gostaria de visualizar o quadro: "${titulo}" ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container', // Estiliza o container principal
          title: 'custom-swal-title', // Estiliza o título
          confirmButton: 'custom-swal-confirm-button', // Estiliza o botão de confirmação
          cancelButton: 'custom-swal-cancel-button', // Estiliza o botão de cancelamento
          popup: 'custom-swal-popup', // Estiliza o popup
          icon: 'custom-swal-icon' // Estiliza o ícone
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };


  const handleEdit = async (quadro) => {
    try {
      const userConfirmed = await showPopUp2(quadro.titulo_quadro); // Passa o título do quadro
      if (!userConfirmed) {
        return; // Se o usuário não confirmar, interrompe a execução
      }

      setVerQuadro(quadro); // Continua se o usuário confirmar
    } catch (error) {
      console.error("Erro ao editar o quadro:", error);
    }
  };



  return (
    <div>

      <ContainerColuna>
        <Box >

          <DivMaster className='scroll'>
            <h3>Meus quadros:</h3>
            <DivBox>

              {quadro
                .filter((quadro) => quadro.id_criador === id) // Filtra os cards com base no id_coluna
                .map((quadro) => ( // Renderiza os cards filtrados
                  <Div key={quadro.id_quadro} onClick={() => handleEdit(quadro)} type="button" >
                    <P>{quadro.titulo_quadro}</P>
                    <Divdata>
                      {excluir && (
                        <Button
                          onClick={(event) => {
                            event.preventDefault(); // Impede o comportamento padrão
                            event.stopPropagation(); // Impede a propagação do evento para o Div pai
                            handleExcluirCartao(quadro);
                          }}
                          title="Excluir"
                          type="button"
                        >
                          <IoCloseSharp />
                        </Button>
                      )}
                    </Divdata>

                  </Div>
                ))}
            </DivBox>

            <DivCabecario>

              <Button onClick={() => handleNovoQuadro()} title="Cria novo quadro">
                <BsCardHeading /> Adicionar quadro
              </Button>
              {!excluir && (
                <Button onClick={() => handleExcluir()} title="Excluir">
                  <IoCloseSharp /> Excluir
                </Button>
              )}
            </DivCabecario>

          </DivMaster>

        </Box>
      </ContainerColuna>

    </div>

  );
};

export default Grid;
