import React from 'react';
import ReactApexChart from 'react-apexcharts';


const BarChartPagina = ({ tPagina, onPageSelect }) => {
  const seriesData = Array.isArray(tPagina) ? tPagina.map(item => item.acessos) : [];
  const categoriesData = Array.isArray(tPagina) ? tPagina.map(item => item.desc_pagina) : [];
  const idsData = Array.isArray(tPagina) ? tPagina.map(item => item.id_pagina) : []; // Obtenha os IDs dos módulos

  const options = {
    chart: {
      type: 'bar',
      height: categoriesData.length * 50,  // Aumentar a altura do gráfico com base no número de categorias
      width: '100%',
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const selectedId = idsData[config.dataPointIndex]; // Obtenha o ID do módulo correspondente ao índice do ponto de dados selecionado
          onPageSelect(selectedId); // Chame a função de callback com o ID do módulo
        }
      },
      toolbar: {
        show: false  // Ocultando as ferramentas do gráfico
      }
    },
    colors: ['#04a44c'],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        barHeight: '70%',  // Aumentar a altura das barras
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: categoriesData,
    },
  };
  
  const series = [{
    data: seriesData,
  }];
  
  return (
    <div style={{ overflowY: 'auto', maxHeight: '300px' }}> {/* Adicionando barra de rolagem vertical */}
      <div id="chart">
        <ReactApexChart options={options} series={series} type="bar" height={categoriesData.length * 50} />
      </div>
    </div>
  );
  
  
};

export default BarChartPagina;
