import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BarChart from "./BarChart.js";
import BarChartPagina from "./BarChartPagina.js";
import BarChartUsuario from "./BarChartUsuario.js";
import GridListaUsuario from "./Grid-lista-usuario.js";


const Table = styled.table`
  width: 32%;

  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 1%;

`;

const Div = styled.div`
  display: flex;
  flex-direction: row


`;

export const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

export const Tr = styled.tr`
  text-align: center;


`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;


`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 

`;

export const Td1 = styled.td`
  padding-top: 15px;
  text-align: center;


`;

export const Td2 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
`;

export const Td3 = styled.td`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  span{
    margin: 0px 5px;
  }

`;

const Input = styled.input`
  width: 345px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;


`;


const Button = styled.button`
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  width: 150px;
  background-color: #076633;
  color: white;
  height: auto;
  font-weight: bold;
  font-size: 18px;
  margin: 5px 20px;
  transition: transform 0.3s;
  background: rgb(128, 128, 128); /* Fundo verde translúcido */
  border: 1px solid rgb(7, 102, 51); /* Borda verde translúcida */
  border-radius: 5px; /* Bordas arredondadas */
  backdrop-filter: blur(10px); /* Efeito de desfoque */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s, border 0.3s;
  
  &:hover {
    transform: scale(1.1);
  }

`;

const Grid = ({ tAcesso, tPagina, tUsuario, somandoUsuarios }) => {




  const [searchTermModulo, setSearchTermModulo] = useState('');
  const [filteredModulo, setFilteredModulo] = useState(tAcesso);
  const [isLoading, setIsLoading] = useState(true);

  const [searchTermPagina, setSearchTermPagina] = useState('');
  const [filteredPagina, setFilteredPagina] = useState(tPagina);
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const [searchTermUsuario, setSearchTermUsuario] = useState('');
  const [filteredUsuario, setFilteredUsuario] = useState(somandoUsuarios);
  const [isLoadingUsuario, setIsLoadingUsuario] = useState(true);












  //Input de pesquisa
  const handleSearchModulo = async (term) => {
    setIsLoading(true);

    try {
      const filtered = tAcesso.filter((user) => {
        const modulo = user.desc_modulo ? user.desc_modulo.toLowerCase() : ''; // Verifica se user.logon é null ou undefined

        return (
          modulo.includes(term.toLowerCase())

        );
      });
      setFilteredModulo(filtered);
    } catch (error) {
      console.error("Erro ao pesquisar:", error);
    }

    setIsLoading(false);

  };

  //Input de pesquisa
  const handleSearchPagina = async (term) => {
    setIsLoadingPage(true);

    try {
      const filtered = tPagina.filter((user) => {
        const pagina = user.desc_pagina ? user.desc_pagina.toLowerCase() : ''; // Verifica se user.logon é null ou undefined

        return (
          pagina.includes(term.toLowerCase())

        );
      });
      setFilteredPagina(filtered);
    } catch (error) {
      console.error("Erro ao pesquisar:", error);
    }

    setIsLoadingPage(false);

  };

  //Input de pesquisa
  const handleSearchUsuario = async (term) => {
    setIsLoadingUsuario(true);

    try {
      const filtered = somandoUsuarios.filter((user) => {
        const usuario = user.login ? user.login.toLowerCase() : ''; // Verifica se user.logon é null ou undefined

        return (
          usuario.includes(term.toLowerCase())

        );
      });
      setFilteredUsuario(filtered);
    } catch (error) {
      console.error("Erro ao pesquisar:", error);
    }

    setIsLoadingUsuario(false);

  };


  useEffect(() => {
    handleSearchModulo(searchTermModulo);
    handleSearchPagina(searchTermPagina);
    handleSearchUsuario(searchTermUsuario);
  }, [searchTermModulo, tAcesso, searchTermPagina, tPagina, searchTermUsuario, tUsuario]);








  //Ao clicar no grafico modulo, filtra o grafico Pagina
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const handleModuleSelect = (id_modulo) => {
    setSelectedPageId(null);
    setSelectedUserId(null);
    setSelectedModuleId(id_modulo);

  };



  //Ao clicar no grafico pagina, filtra o grafico Usuario
  const [selectedPageId, setSelectedPageId] = useState(null);
  const handlePageSelect = (id_pagina) => {
    setSelectedModuleId(null);
    setSelectedUserId(null);
    setSelectedPageId(id_pagina);

  };

  //Ao clicar no grafico pagina, filtra o grafico Usuario
  const [selectedUserId, setSelectedUserId] = useState(null);
  const handleUserSelect = (login) => {
    setSelectedModuleId(null);
    setSelectedPageId(null);
    setSelectedUserId(login);

  };





  const handleZerarGrafico = () => {
    setSelectedModuleId(null);
    setSelectedPageId(null);
    setSelectedUserId(null);
    window.location.reload();

  };






  const [usuariosFiltrados, setUsuarios] = useState();
  useEffect(() => {
    if (selectedModuleId !== null) {
      const usuariosFiltrados = Array.isArray(tUsuario) ? tUsuario.filter(usuario => usuario.id_modulo === selectedModuleId) : [];
      setUsuarios(usuariosFiltrados);


    } else if (selectedPageId !== null) {
      const usuariosFiltrados = Array.isArray(tUsuario) ? tUsuario.filter(usuario => usuario.id_pagina === selectedPageId) : [];
      setUsuarios(usuariosFiltrados);


    } else if (selectedUserId !== null) {
      const usuariosFiltrados = Array.isArray(tUsuario) ? tUsuario.filter(usuario => usuario.login === selectedUserId) : [];
      setUsuarios(usuariosFiltrados);

    } else {
      const usuariosFiltrados = tUsuario;
      setUsuarios(usuariosFiltrados)
    }
  }, [selectedModuleId, selectedPageId, selectedUserId, tUsuario]);



  return (

    <>

      <Div>
        <Button onClick={handleZerarGrafico}>
          Limpar
        </Button>
      </Div>

      <Div>
        <Table>
          <Td3 colSpan="7">
            <Input
              type="text"
              placeholder="Pesquisar"
              value={searchTermModulo}
              onChange={(e) => setSearchTermModulo(e.target.value)}
            />


          </Td3>
          <BarChart tAcesso={filteredModulo} onModuleSelect={handleModuleSelect} />
        </Table>

        <Table>
          <Td3 colSpan="7">
            <Input
              type="text"
              placeholder="Pesquisar"
              value={searchTermPagina}
              onChange={(e) => setSearchTermPagina(e.target.value)}
            />


          </Td3>
          <BarChartPagina tPagina={filteredPagina} onPageSelect={handlePageSelect} />
        </Table>

        <Table>
          <Td3 colSpan="7">
            <Input
              type="text"
              placeholder="Pesquisar"
              value={searchTermUsuario}
              onChange={(e) => setSearchTermUsuario(e.target.value)}
            />


          </Td3>
          <BarChartUsuario somandoUsuarios={filteredUsuario} onUserSelect={handleUserSelect} />
        </Table>
      </Div>
      <Div>
        <GridListaUsuario usuariosFiltrados={usuariosFiltrados} />
      </Div>


    </>
  );
};


export default Grid;
