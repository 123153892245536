import axios from "axios";
import React, { useRef } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { TbArrowsMoveHorizontal } from "react-icons/tb";
import Swal from "sweetalert2";
import './style.css';

const FormContainer = styled.form`
  width: auto;
  background-color: #515151;
  display: flex; 
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;

`;

const DivContainer = styled.form`
  width: 900px;
  background-color: #515151;
  display: flex; 
  align-items: flex-start;
  flex-direction: row;
  border: 1px solid #ccc;
`;



const DivButtons = styled.div`
  max-width:100%;
  background-color: #515151;
  display: flex; 
  padding: 5px 0px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;





const InputArea = styled.div`
  width: 350px;
  height: auto; 
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px;

  select {
    width: 200px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 1px;
    padding: 0px 10px;
    background-color: #515151;
    font-size: 16px;
    color: #fff;
  }
  
`;




const Input = styled.input`
  width: 170px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 1px;
  padding: 0px 10px;
  background-color: #515151;
  font-size: 16px;
  color: #fff;
`;



const Label = styled.label`
  color: #fff;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  margin: 0px 5px;
  padding: 0px 0px 0px 10px; 


`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  width: 150px;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #515151;
  height: 42px;
  font-weight: bold;
  margin: 5px;
  transition: transform 0.1s;
  gap: 10px;
  background: rgba(255, 255, 255, 0.1); 
  border: 1px solid rgba(255, 255, 255, 0.9); /* Borda translúcida */
  border-radius: 10px; /* Bordas arredondadas */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s;
  color: #fff;
  &:hover {
    transform: scale(1.0);
  }
`;










const Form = ({ editarCartao, urlApi, modalMover, colunas, fetchColunas, modalEditarCartao }) => {



  //Token da api
  const token = localStorage.getItem('token');
  const id = parseInt(localStorage.getItem('id'));

  //---------------------------------------------------------------------------//
  //Recebe os valores para editar, tipo onEdit
  const ref = useRef();


  //---------------------------------------------------------------------------//
  //Filtra as tarefas
  const colunasFiltradas = colunas.filter(v => v.id_quadro === editarCartao.id_quadro);
  const colunaAtual = colunasFiltradas.find(v => v.id_coluna === editarCartao.id_coluna);
  const colunasRestantes = colunasFiltradas.filter(v => v.id_coluna !== colunaAtual?.id_coluna);


  //---------------------------------------------------------------------------//
  //Delete as tarefas
  const handleSave = async (index) => {
    const user = ref.current;


    const id_colunaValue = user.id_coluna.value;
    const userConfirmed = await showPopUp();

    if (!userConfirmed) {
      return;
    }


    try {
      // Tenta realizar a requisição para deletar o usuário
      const response = await axios.put(
        `${urlApi}/gerenciamento-card/mudando-card/${editarCartao.id_card}`,
        { id_coluna: id_colunaValue },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      fetchColunas();
      toast.success("Cartão movido com sucesso.");
      setTimeout(function () {
        modalMover();
        modalEditarCartao();
      }, 1000);



    } catch (error) {
      // Tratamento de erros
      console.error('Erro ao deletar o usuário:', error);

      let errorMessage = 'Erro desconhecido ao deletar.';
      if (error.response) {
        errorMessage = error.response.data || errorMessage;
        console.log('Erro na resposta:', error.response);
      } else if (error.request) {
        console.log('Erro na requisição:', error.request);
      } else {
        console.log('Erro na configuração da requisição:', error.message);
      }

      toast.error(errorMessage);

      // Cria log de erro na exclusão

    }

  };


  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Deseja confirmar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container', // Estiliza o container principal
          title: 'custom-swal-title', // Estiliza o título
          confirmButton: 'custom-swal-confirm-button', // Estiliza o botão de confirmação
          cancelButton: 'custom-swal-cancel-button', // Estiliza o botão de cancelamento
          popup: 'custom-swal-popup', // Estiliza o popup
          icon: 'custom-swal-icon' // Estiliza o ícone
        },
      }).then((result) => {
        resolve(result.value);
      });
    });
  };




  return (
    <div >
      <FormContainer>
        <DivContainer ref={ref} >

          <InputArea>
            <Label>Lista atual:</Label>
            <Input
              value={colunaAtual.descricao_coluna}
              readOnly
              disabled
            />
          </InputArea>

          <InputArea>
            <Label>Mover para:</Label>
            <select
              name="id_coluna"

            >
              <option value="">Escolha </option>
              {colunasRestantes.map((user, index) => (
                <option key={index} value={user.id_coluna}>
                  {user.descricao_coluna}
                </option>
              ))}
            </select>
          </InputArea>

          <DivButtons>

            <div style={{ display: 'flex', flexDirection: 'column' }}>

              {(id === editarCartao.id_criador) && (
                <Button onClick={() => handleSave()} type="button">
                  <TbArrowsMoveHorizontal size={18} color={"blue"} /> Salvar
                </Button>
              )}
            </div>

          </DivButtons>

        </DivContainer>

        
      </FormContainer>


      <style jsx global>{`
      .swal2-container.custom-swal-container {
        z-index: 9999; 
      }
    `}</style>
    </div >

  );
};

export default Form;


