import React, { useState, useEffect } from "react";
import axios from "axios"

import GridUsuarios from "./components/Grid-usuarios.js";
import { useUsuariosHook } from '../../../hook/getUsuarios.js';

import GlobalStyle from "../../../styles/global.js";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../../styles/imagem/Logomarca-06.png";
import { toast, ToastContainer } from "react-toastify";
import { ImExit } from "react-icons/im";
import { FaBars, FaWindowClose, FaHome } from "react-icons/fa";


const DivLayout = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  box-sizing: border-box;
`;

const Title = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 5.5rem;
  background-color: #076633;
`;

const Title2 = styled.h2`
  width: 100%;
  color: #fff;
  font-size: 28px;
  display: flex;
  justify-content: space-around; 
  align-items: center;

  img{

    padding-top: 1%;
  }  
`;

const TitleNavBar = styled.nav`
  width: 100%;
  height: 3.5rem;
  background-color: #A6A29F;
  display: flex;
  justify-content: space-around;
  align-items: center;

  &.sidebar.closed {
  display: none; 
  }

  &.sidebar.open {
  display: block; 
  }

`;

const Title3 = styled.h2`
  width: 100%;
  color: #fff;
  font-size: 28px;
  display: flex;
  justify-content: space-around; 
  align-items: center;
   
`;

const Div = styled.div`
  position: fixed;
  top:0;
  left:0;
  display: flex;
  flex-direction: column;
`;

const Filtro = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;


`;

const ButtonSide = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  cursor: pointer;
  border: none;
  background-color: #A6A29F;
  color: #fff;
  font-size: 20px;

`;

const ButtonSide1 = styled.button`
  cursor: pointer;
  border: none;
  background-color: #076633;
  color: #fff;
  font-size: 18px;

  &.toggle-btn {
    position: absolute;
    top: 2;
    left: 0;
    padding-left: 1vw;
  }

  &.button-exit{
    position: absolute;
    top: 2;
    right: 0;
    padding-right: 1vw;
  }

`;





function Usuarios() {

  //const urlFront = "http://192.168.100.103:3050/gestao/";
  //const urlApi = "http://192.168.100.103:8850/api";
  //const urlLogin = "http://192.168.100.103:3050/";
  const urlFront = "/gestao/";
  const urlApi = "/api";
  const urlLogin = "/";

  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id');

  const { usuarios } = useUsuariosHook();


  const [onEdit, setOnEdit] = useState(null);





  //Links
  const handleDashboard = () => {
    const url = urlFront;
    window.location.href = url;
  };

  const handleExit = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('usuario');

    const url = `${urlLogin}login`;
    window.location.href = url;
  };

  //Verifica o token do usuário
  const [tToken, setToken] = useState("");
  const getToken = async () => {
    try {
      const res = await axios.get(`${urlApi}/login/token`, { headers: { Authorization: `Bearer ${token}` } });
      setToken(res.data);
    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}/login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }
  };


  //Get retorna os acessos
  const [tAcesso, setAcesso] = useState("");
  const getAcesso = async () => {
    try {
      const res = await axios.get(`${urlApi}/vwacesso/modulo`, { headers: { Authorization: `Bearer ${token}` } });
      setAcesso(res.data);
    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}/login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }
  };

  //Get retorna os acessos
  const [tPagina, setPagina] = useState("");
  const getPagina = async () => {
    try {
      const res = await axios.get(`${urlApi}/vwacesso/pagina`, { headers: { Authorization: `Bearer ${token}` } });
      setPagina(res.data);
    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}/login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }
  };



  //Get retorna os acessos
  const [tUsuario, setUsuario] = useState("");
  const [somandoUsuarios, setResultado] = useState([]);

  const getUsuario = async () => {
    try {
      const res = await axios.get(`${urlApi}/vwacesso/usuario`, { headers: { Authorization: `Bearer ${token}` } });

      // Armazenando a resposta no estado tUsuario
      setUsuario(res.data);

      // Usando res.data diretamente para calcular acessos por login
      const acessosPorLogin = res.data.reduce((acc, curr) => {
        if (!curr.acessos) {
          console.warn(`Propriedade 'acessos' não encontrada para o login: ${curr.login}`);
          return acc; // Pular este registro se não houver 'acessos'
        }
        if (!acc[curr.login]) {
          acc[curr.login] = { login: curr.login, acessos: 0 };
        }
        acc[curr.login].acessos += curr.acessos;
        return acc;
      }, {});

      // Convertendo o objeto de volta para um array
      const resultadoFinal = Object.values(acessosPorLogin);

      // Classificando o array pela coluna 'acessos' de forma decrescente
      resultadoFinal.sort((a, b) => b.acessos - a.acessos);

      setResultado(resultadoFinal); // Atualizando o estado resultado
    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}/login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }
  };




  useEffect(() => {
    getAcesso();
    getPagina();
    getPagina();
    getUsuario();
  }, []);


  useEffect(() => {
    const intervalId = setInterval(() => {
      getToken();
    }, 300000);


    return () => clearInterval(intervalId);
  }, [])



  const [isOpenSide, setIsOpenSide] = useState(false);
  const toggleSidebar = () => {
    setIsOpenSide(!isOpenSide);

  };

  useEffect(() => {
    const usuarioFiltrado = usuarios.find(usuario => usuario.id_usuario === id);

    if (usuarioFiltrado) {
      // Verifica se o tipo de indicador é diferente de "ADMIN" e "GESTOR"
      if (usuarioFiltrado.tipo_indicadores !== "ADMIN" && usuarioFiltrado.tipo_indicadores !== "GESTOR") {
        const url = `/login`;
        window.location.href = url;
      }
    }
  }, [id, usuarios]);






  //Abre o modal 'Ver Usuario'
  const [isModalOpenVerUsuario, setIsModalOpenVerUsuario] = useState(false);
  const modalVerUsuario = () => {
    setIsModalOpenVerUsuario(!isModalOpenVerUsuario);

  };

  return (
    <>




      <div >
        <DivLayout>
          <Title>
            <div>
              <ButtonSide1 className="toggle-btn desativaBotao" onClick={toggleSidebar}>
                {isOpenSide ? <FaWindowClose className="gap" /> : <FaBars />}
              </ButtonSide1>
            </div>
            <Title2>
              <div>
                <h2>Acessos</h2>
              </div>


              <img src={logo} alt="Logo" />
            </Title2>
            <div>
              <ButtonSide1 className="button-exit" onClick={handleExit} title={"Sair"}>
                <ImExit />
              </ButtonSide1>
            </div>
          </Title>

          <TitleNavBar className={`sidebar ${isOpenSide ? 'open' : 'closed'}`}>
            <Title3>
              <ButtonSide onClick={handleDashboard} className="button-usuarios" >
                <><FaHome className="gap" /> Dashboard</>
              </ButtonSide>

            </Title3>
          </TitleNavBar>
        </DivLayout>

        <Container>
          <Filtro>

            <GridUsuarios setOnEdit={setOnEdit} modalVerUsuario={modalVerUsuario} usuarios={usuarios} tAcesso={tAcesso} tPagina={tPagina} tUsuario={tUsuario} somandoUsuarios={somandoUsuarios} />

          </Filtro>

        </Container>
        <Div>

          <ToastContainer autoClose={2500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
          <GlobalStyle />
        </Div>
      </div>
    </>
  );
}

export default Usuarios;
