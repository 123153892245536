import axios from "axios";
import React, { useRef } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ImCheckmark } from "react-icons/im";
import Swal from "sweetalert2";
import './style.css';


const FormContainer = styled.form`
  width: auto;
  background-color: #515151;
  display: flex; 
  align-items: flex-start;
  flex-direction: column;

`;

const Div = styled.div`
  max-width:100%;
  background-color:#515151;
  display: flex; 
  padding: 10px 10px;
  align-items: flex-end;
  gap: 20px 0px;
  flex-direction: row;
 
`;


const TextAreaContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  
  textarea{
    width: 490px;
    height: 100px;
    background-color: #5d5d5d;
    border: 1px solid #ccc;
    color:#fff;
    padding: 5px;
  }


`;

const InputArea = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;

  select {
    width: 220px;
    height: 41.5px;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 1px;
    background-color: #5d5d5d;
  }

`;


const InputTitle = styled.input`
  width: 480px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 1px;
  padding: 0px 10px;
  background-color: #5d5d5d;
  color:#fff;
`;

const Label = styled.label`
  color: #fff;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  margin: 0px 5px;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; 
  padding-left: 1px;
  flex-direction: column;

  @media (max-width: 500px) {
    justify-content: center; 
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  width: 150px;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border: 1px solid #e8eaeb;
  background-color: #e8eaeb;
  height: 42px;
  font-weight: bold;
  margin: 5px;
  transition: transform 0.1s;
  gap: 10px;
  background: rgba(255, 255, 255, 0.1); 
  border: 1px solid rgba(255, 255, 255, 0.9); /* Borda translúcida */
  border-radius: 10px; /* Bordas arredondadas */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s;
  color: #fff;
  &:hover {
    transform: scale(1.1);
  }
`;



const Form = ({ urlApi, modalNovoQuadro, fetchQuadro }) => {
  const ref = useRef();


  //Token da api
  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id');



  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Deseja confirmar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container', // Estiliza o container principal
          title: 'custom-swal-title', // Estiliza o título
          confirmButton: 'custom-swal-confirm-button', // Estiliza o botão de confirmação
          cancelButton: 'custom-swal-cancel-button', // Estiliza o botão de cancelamento
          popup: 'custom-swal-popup', // Estiliza o popup
          icon: 'custom-swal-icon' // Estiliza o ícone,
        },
      }).then((result) => {
        resolve(result.value);
      });
    });
  };






  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = ref.current;


    const id_criadorValue = id;
    const titulo_quadroValue = user.titulo_quadro.value;
    const descricao_quadroValue = user.descricao_quadro.value;




    if (!titulo_quadroValue || !descricao_quadroValue) {
      return toast.warn("Preencha todos os campos!");
    } else {
      const userConfirmed = await showPopUp(); // Aguarde a resolução da Promise



      if (!userConfirmed) {
        return;
      }
      try {
        const requestData1 = {

          id_criador: id_criadorValue,
          titulo_quadro: titulo_quadroValue,
          descricao_quadro: descricao_quadroValue,

        };
        const response1 = await axios.post(`${urlApi}/quadro`, requestData1, { headers: { Authorization: `Bearer ${token}` } });

        setTimeout(function () {
          modalNovoQuadro()
        }, 1000);

        fetchQuadro();
      } catch (error) {
        if (error.response) {
          const errorMessage = error.response.data.error;
          toast.error(errorMessage);
        }
      }

    }

  };



  



  return (
    <div >
      <FormContainer ref={ref} onSubmit={handleSubmit}>

        <Div>

        </Div>
        <Div>
          <InputArea>
            <Label>Titulo:</Label>
            <InputTitle
              name="titulo_quadro"
              type="text"
            />
          </InputArea>
        </Div>


        <Div>
          <TextAreaContainer>
            <Label>Descrição:</Label>
            <textarea id="descricao_quadro" name="msg" ></textarea>
          </TextAreaContainer>
        </Div>







        <ButtonContainer>
          <Button title="Salvar" type="submit" >
            <ImCheckmark color={"#03A64A"} size={20} /> Criar quadro
          </Button>
        </ButtonContainer>
      </FormContainer>
      <style jsx global>{`
      .swal2-container.custom-swal-container {
        z-index: 9999; 
      }
    `}</style>
    </div>

  );
};

export default Form;


