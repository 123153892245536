import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ImCheckmark,  ImBin, ImCross } from "react-icons/im";
import { FaPlus } from "react-icons/fa";
import Swal from "sweetalert2";
import './style.css'

const FormContainer = styled.form`
  width: auto;
  background-color: #515151;
  display: flex; 
  align-items: flex-start;
  flex-direction: row;

`;

const DivContainer = styled.form`
  width: auto;
  background-color: #515151;
  display: flex; 
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #e8eaeb;
`;

const Div = styled.div`
  width: 95%;
  background-color:#515151;
  display: flex; 
  padding: 10px 10px;
  align-items: flex-end;
  gap: 20px 0px;
  flex-direction: row;
 
`;
const Div2 = styled.div`
  width: 95%;
  background-color:#515151;
  display: flex; 
  padding: 10px 10px;
  align-items: flex-end;
  gap: 20px 0px;
  flex-direction: column;
 
`;



const DivButtons = styled.div`
  max-width:100%;
  background-color: #515151;
  display: flex; 
  padding: 5px 0px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;




const InputArea = styled.div`
  width: auto;
  height: auto; 
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;

  select {
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 1px;
    padding: 0px 10px;
    background-color: #5d5d5d;
    font-size: 16px;
  }
  
`;
const InputArea2 = styled.div`
  width: auto;
  height: auto; 
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px;

  select {
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 1px;
    padding: 0px 10px;
    background-color: #5d5d5d;
    font-size: 16px;
    color: #fff;
  }
  
`;


const InputDescricao = styled.textarea`
  width: 700px; 
  height: 250px; 
  border: 1px solid #ccc;
  border-radius: 1px;
  padding: 10px 10px 00px 20px;
  background-color: #5d5d5d;
  color: #fff;
  resize: none; /* Desabilitar o redimensionamento manual */
  box-sizing: border-box; /* Incluir o padding e a borda na largura total */
  overflow: auto; /* Permitir a rolagem automática */
  white-space: pre-wrap; /* Manter quebras de linha e espaços */
`;





const Select = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 1px;
  padding: 0px 10px;
  background-color: #515151;
  font-size: 16px;
  color: #fff;
`;

const Label = styled.label`
  color: #fff;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  margin: 0px 5px;
  padding: 0px 0px 0px 10px; 


`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  width: 150px;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #515151;
  height: 42px;
  font-weight: bold;
  margin: 5px;
  transition: transform 0.1s;
  gap: 10px;
  background: rgba(255, 255, 255, 0.1); 
  border: 1px solid rgba(255, 255, 255, 0.9); /* Borda translúcida */
  border-radius: 10px; /* Bordas arredondadas */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s;
  color: #fff;
  &:hover {
    transform: scale(1.1);
  }
`;



const DivList = styled.div`
  max-width:100%;
  background-color: #515151;
  display: flex; 
  padding: 10px 0px;
  align-items: start;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
`;

const Tr = styled.tr`
  text-align: center;
  padding-bottom: 05px;
  border: 1px solid green;
`;

export const Td = styled.td`
  font-size: 14px;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;

`;

const Table = styled.table`
  width: 710px;
  background-color: #515151;
  padding: 5px 20px;
  color: #fff;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 05px; 
`;

export const Th = styled.th`
  color: #fff;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
`;


const DivInt = styled.div`
  width: 100%;
  background-color: #515151;
  display: flex; 
  flex-direction: column; /* Garantindo que os elementos dentro de DivInt fiquem em coluna */
  gap: 10px;
  color: #fff;
`;
const Form = ({ editarCartao, urlApi, usuarios, checkbox, fetchCheckbox, visualizador }) => {

  //Token da api
  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id');

  //---------------------------------------------------------------------------//
  //Recebe os valores para editar, tipo onEdit
  const ref = useRef();
  useEffect(() => {
    if (editarCartao) {
      const user = ref.current;

    }

  }, [editarCartao]);




  //---------------------------------------------------------------------------//
  // Funções responsaveis por gerar o input e o select data
  // Estado para armazenar os campos de input com descricão e meta
  const [inputFields, setInputFields] = useState([]);

  // Função para adicionar um novo campo de input
  const addInputField = () => {
    setInputFields([...inputFields, { descricao_check: '', meta_conclusao: '', id_responsavel_tarefa: '' }]); // Adiciona um novo objeto vazio
  };

  const handleTarefa = () => {
    addInputField();
  };

  // Função para lidar com mudanças nos campos de input
  const handleInputChange = (index, field, value) => {
    const newInputFields = [...inputFields];
    newInputFields[index][field] = value;
    setInputFields(newInputFields);
  };

  // Função para enviar os dados do checkbox
  const handleCheckbox = async (index) => {
    const descricao_checkValue = inputFields[index].descricao_check;
    const meta_conclusaoValue = inputFields[index].meta_conclusao;
    const id_responsavel_tarefaValue = inputFields[index].id_responsavel_tarefa;

    if (!descricao_checkValue || !meta_conclusaoValue) {
      return toast.warn("Preencha todos os campos!");
    }

    try {
      const requestData1 = {
        id_card: editarCartao.id_card,
        descricao_check: descricao_checkValue,
        meta_conclusao: meta_conclusaoValue,
        id_responsavel_tarefa: id_responsavel_tarefaValue,
        image: nameArquivo,
      };
      const response1 = await axios.post(`${urlApi}/gerenciamento-checkbox`, requestData1, { headers: { Authorization: `Bearer ${token}` } });

      enviarEmail();
      // Limpar os campos após salvar (opcional)
      const newInputFields = [...inputFields];
      newInputFields[index] = { descricao_check: '', meta_conclusao: '', id_responsavel_tarefa: '' };
      setInputFields(newInputFields);

    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.error;
        const details = error.response.data.details;
        const dbError = error.response.data.dbError;
        console.log("Error:", errorMessage);
        console.log("Details:", details);
        console.log("Database Error:", dbError);
        toast.error("Erro ao salvar os dados: " + errorMessage);
      }
    }

    fetchCheckbox();
  };

  //Não deixa a data da tarefa ser maior que a data de entrega.
  const handleMetaConclusaoChange = (index, newValue) => {
    const metaConclusaoValue = new Date(newValue); // Converte o valor de entrada para um objeto Date
    const onEditMetaConclusao = new Date(editarCartao.meta_conclusao); // Converte o valor de onEdit.meta_conclusao para um objeto Date

    // Verifica se a nova data não é maior que onEdit.meta_conclusao
    if (metaConclusaoValue > onEditMetaConclusao) {
      toast.error("A data selecionada não pode ser maior que a data de entrega do plano.");
      return;
    }

    // Atualiza o campo se a data for válida
    const updatedFields = [...inputFields];
    updatedFields[index].meta_conclusao = newValue;
    setInputFields(updatedFields);
  };


  const handleInputResponsavel = (index, value) => {
    const newInputFields = [...inputFields];
    newInputFields[index].id_responsavel_tarefa = value; // Atualizando o campo correto
    setInputFields(newInputFields);
    console.log(newInputFields)
  };


  // Função para remover um campo de input específico
  const removeInputField = (index) => {
    const updatedFields = [...inputFields];
    updatedFields.splice(index, 1); // Remove o campo pelo índice
    setInputFields(updatedFields);
  };



  //---------------------------------------------------------------------------//
  //Filtra as tarefas
  const checkFiltrados = checkbox.filter(v => v.id_card === editarCartao.id_card);



  //---------------------------------------------------------------------------//
  //Delete as tarefas
  const handleDelete = async (check) => {

    const userConfirmed = await showPopUp();

    if (!userConfirmed) {
      return;
    }


    try {
      // Tenta realizar a requisição para deletar o usuário
      const { data } = await axios.delete(`${urlApi}/gerenciamento-checkbox/${check.id_check}`, {
        headers: { Authorization: `Bearer ${token}` },
      });


      toast.success("Tarefa deletada com sucesso.");


      fetchCheckbox();
    } catch (error) {
      // Tratamento de erros
      console.error('Erro ao deletar o usuário:', error);

      let errorMessage = 'Erro desconhecido ao deletar.';
      if (error.response) {
        errorMessage = error.response.data || errorMessage;
        console.log('Erro na resposta:', error.response);
      } else if (error.request) {
        console.log('Erro na requisição:', error.request);
      } else {
        console.log('Erro na configuração da requisição:', error.message);
      }

      toast.error(errorMessage);

      // Cria log de erro na exclusão

    }

  };


  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Deseja confirmar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container', // Estiliza o container principal
          title: 'custom-swal-title', // Estiliza o título
          confirmButton: 'custom-swal-confirm-button', // Estiliza o botão de confirmação
          cancelButton: 'custom-swal-cancel-button', // Estiliza o botão de cancelamento
          popup: 'custom-swal-popup', // Estiliza o popup
          icon: 'custom-swal-icon' // Estiliza o ícone
        },
      }).then((result) => {
        resolve(result.value);
      });
    });
  };


  //Upload imagem
  const [message, setMensagem] = useState('');
  const [nameArquivo, setNameArquivo] = useState('');
  const uploadImageOnChange = async (e) => {
    const selectedImage = e.target.files[0];

    const formData = new FormData();
    formData.append('image', selectedImage);
    setNameArquivo(selectedImage.name);
    const headers = {
      'headers': {
        'Content-Type': 'multipart/form-data'
      }
    }

    try {
      const response = await axios.post(`${urlApi}/upload-gerenciamento/upload-image-tarefa`, formData, headers);
      setMensagem(response.data.message);
    } catch (err) {
      if (err.response) {
        setMensagem(err.response.data.message);
      } else {
        setMensagem("Erro: verifique o tipo de arquivo que está sendo anexado")
      }
    }
  };


  const usuariosVisualizadores = visualizador
    .filter(v => v.id_quadro === editarCartao.id_quadro) // Ou editarCartao.id_quadro, caso seja um quadro e não coluna
    .map(v => usuarios.find(user => user.id_usuario === v.id_usuario)) // Mapeia para encontrar os usuários correspondentes
    .filter(Boolean); // Remove possíveis undefined caso algum id_usuario não seja encontrado


  
  //fornece o id para selecionar o email
  const [usuarioEmail, setUsuarioEmail] = useState();

  const [emailRecebe, setEmailRecebe] = useState()
  //extrai o email do usuário selecionado
  useEffect(() => {
    if (usuarioEmail && (usuarioEmail !== id)) {
      const usuarioEmail2 = usuarios.find(user => user.id_usuario == usuarioEmail);
      const emailRecebe2 = usuarioEmail2 ? usuarioEmail2.email : 'Usuário não encontrado';

      setEmailRecebe(emailRecebe2)
    }
  }, [usuarioEmail]);


  //Renderiza o login do criador
  const usuarioCriador = usuarios.find(user => user.id_usuario === editarCartao.id_criador);
  const loginCriador = usuarioCriador ? usuarioCriador.login : '';


  const enviarEmail = () => {

    axios.get(`${urlApi}/email-gerenciamento/nova-tarefa`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { userTo: emailRecebe, criador: loginCriador, titulo: editarCartao.titulo_card }
    })
      .then(response => {
        console.log("E-mail enviado com sucesso:", response.data.message);
      })
      .catch(error => {
        if (error.response) {
          console.error("Status:", error.response.status);
          console.error("Headers:", JSON.stringify(error.response.headers, null, 2));
          console.error("Data:", error.response.data);
        } else if (error.request) {
          console.error("Request não respondida:", error.request);
        } else {
          console.error("Erro na configuração da requisição:", error.message);
        }
        console.error("Configuração completa:", error.config);
      });
  };


  return (
    <div >
      <FormContainer>
        <DivContainer ref={ref} >
          <>
            {(id == editarCartao.id_criador || id == editarCartao.id_responsavel) && (
              <Div2>
                {inputFields.map((field, index) => (
                  <DivList key={index}>
                    <DivInt>
                      <InputDescricao
                        name={`descricao_check_${index}`}
                        type="text"
                        value={field.descricao_check || ''}
                        onChange={(e) => handleInputChange(index, 'descricao_check', e.target.value)}
                        placeholder={`Descreva aqui a tarefa ${index + 1}`}
                      />
                    </DivInt>
                    <Div>
                      <InputArea2>
                        <Label>Responsável:</Label>
                        <select
                          name="id_responsavel_tarefa"
                          isSearchable
                          value={field.id_responsavel_tarefa}
                          onChange={(e) => {
                            handleInputResponsavel(index, e.target.value); // Chama a função handleInputResponsavel
                            setUsuarioEmail(e.target.value); // Atualiza o email
                          }}
                        >
                          <option value="">Escolha </option>
                          {usuariosVisualizadores.map((user, index) => (
                            <option key={index} value={user.id_usuario}>
                              {user.login} - {user.nome}
                            </option>
                          ))}
                        </select>
                      </InputArea2>
                      <InputArea>
                        <Select
                          type="date"
                          value={field.meta_conclusao}
                          onChange={(e) => handleMetaConclusaoChange(index, e.target.value)}
                        />
                      </InputArea>
                      <Button
                        type="button"
                        onClick={() => handleCheckbox(index)}
                        style={{ marginLeft: '8px' }}
                      >
                        <ImCheckmark color={'green'} />Salvar
                      </Button>
                      <Button
                        type="button"
                        onClick={() => removeInputField(index)}
                        style={{ marginLeft: '8px' }}
                      >
                        <ImCross color={'red'} />Fechar
                      </Button>
                    </Div>
                  </DivList>
                ))}
              </Div2>

            )}
          </>
          <>
            <Div>
              <Table>
                <>
                  {checkFiltrados.length > 0 && (
                    <Tbody >
                      {checkFiltrados.map((check) => (
                        <Tr key={check.id_check} >
                          <Td width="75%" style={{ textAlign: 'left' }}>{check.descricao_check}</Td>
                          <Td width="auto"></Td>
                          <>
                            {(id == editarCartao.id_card || id == editarCartao.id_responsavel) && (
                              <Td width="5%" onClick={() => handleDelete(check)} style={{ textAlign: 'right' }}>
                                <ImBin size={18} color="Red" />
                              </Td>
                            )}
                          </>

                        </Tr>
                      ))}
                    </Tbody>
                  )}
                </>
              </Table>
            </Div>
          </>
        </DivContainer>

        <FormContainer>
          <DivButtons>

            <div style={{ display: 'flex' }}>

              <Button onClick={handleTarefa} type="button">
                <FaPlus size={18} color={"gold"} /> Nova Tarefa
              </Button>
            </div>

          </DivButtons>
        </FormContainer>
      </FormContainer>


      <style jsx global>{`
      .swal2-container.custom-swal-container {
        z-index: 9999; 
      }
    `}</style>
    </div >

  );
};

export default Form;


