import React from 'react';
import styled from 'styled-components';
import Swal from "sweetalert2";
import './style.css'


const Box = styled.div`
  width: 100vw;  
`;

const DivMaster = styled.div`
  height: auto; 
  width: 98%; 
  flex: 1;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  margin: 5px;
  gap: 10px;
  background: rgba(255, 255, 255, 0.1); 
  border: 1px solid rgba(255, 255, 255, 0.3); 
  border-radius: 10px; 
  backdrop-filter: blur(10px); 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  transition: transform 0.1s, background 0.3s;

  /* Lógica de scroll horizontal aplicada */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap; /* Impede quebra de linha */
  overflow-x: auto; /* Habilita scroll horizontal */
  white-space: nowrap; /* Impede que os itens quebrem para a próxima linha */

  h3 {
    text-align: start;
    color: white;
  }
`;
const DivBox = styled.div`
  text-align: center;
  box-sizing: border-box;
  margin: 5px;
  display: flex;
  gap: 10px
`;

const ContainerColuna = styled.div`
  display: flex;
  align-items: flex-start; 
  justify-content: flex-start; /* Alinha à esquerda para crescimento horizontal */
  flex-wrap: nowrap; /* Evita quebra de linha, permite crescimento horizontal */
  padding: 10px; 
  gap: 10px; 
  background-color: #373737;
  width: 100%;
  height: auto;
  overflow-x: auto; /* Habilita rolagem horizontal */
  white-space: nowrap; /* Impede que o conteúdo quebre para a próxima linha */

`;
const Div = styled.div`
  width: 250px; /* Largura fixa para os cartões */
  height: 150px; /* Altura fixa dos cartões */
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  align-items: flex-start; 
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f4f4f5;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1); 
  transition: transform 0.2s, background 0.3s;
  
  &:hover {
    transform: scale(1.03);
  }

  p {
    color: #fff;
  }
`;



const Grid = ({ quadro, setVerQuadro, visualizador, usuarios }) => {


  const id = parseInt(localStorage.getItem('id'));


  //---------------------------------------------------------------------------------------------------------------//

  const showPopUp2 = (titulo) => {
    return new Promise((resolve) => {
      Swal.fire({
        title: `Gostaria de visualizar o quadro: "${titulo}" ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container', // Estiliza o container principal
          title: 'custom-swal-title', // Estiliza o título
          confirmButton: 'custom-swal-confirm-button', // Estiliza o botão de confirmação
          cancelButton: 'custom-swal-cancel-button', // Estiliza o botão de cancelamento
          popup: 'custom-swal-popup', // Estiliza o popup
          icon: 'custom-swal-icon' // Estiliza o ícone
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };


  const handleEdit = async (quadro) => {
    try {
      const userConfirmed = await showPopUp2(quadro.titulo_quadro); // Passa o título do quadro
      if (!userConfirmed) {
        return; // Se o usuário não confirmar, interrompe a execução
      }

      setVerQuadro(quadro); // Continua se o usuário confirmar
    } catch (error) {
      console.error("Erro ao editar o quadro:", error);
    }
  };





  return (
    <div>

      <ContainerColuna>
        <Box >

          <DivMaster className='scroll'>
            <h3>Quadros que foram compartilhados com você:</h3>
            <DivBox>
              {quadro
                .filter(q => 
                  visualizador.some(v => v.id_quadro === q.id_quadro && v.id_usuario === id) && 
                  q.id_criador !== id // Adiciona a condição para excluir quadros do criador
                )
                .map((quadro) => {
                  const usuarioCriador = usuarios.find(user => user.id_usuario === quadro.id_criador);
                  const loginCriador = usuarioCriador ? usuarioCriador.login : '';

                  return (
                    <Div key={quadro.id_quadro} onClick={() => handleEdit(quadro)} type="button">
                      <p>{quadro.titulo_quadro}</p>
                      <p>{loginCriador}</p>  {/* Renderiza o login do criador do quadro */}
                    </Div>
                  );
                })
              }
            </DivBox>

          </DivMaster>

        </Box>
      </ContainerColuna>

    </div>

  );
};

export default Grid;
