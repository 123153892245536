import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ImCheckmark } from "react-icons/im";

import './style.css'


const FormContainer = styled.form`
  width: auto;
  background-color: #515151;
  display: flex; 
  align-items: flex-start;
  flex-direction: row;

`;

const DivContainer = styled.form`
  width: auto;
  background-color: #515151;
  display: flex; 
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #ccc;
`;

const Div = styled.div`
  width:95%;
  background-color: #515151;
  display: flex; 
  padding: 10px 10px;
  align-items: flex-end;
  gap: 20px 0px;
  flex-direction: row;
 
`;

const DivButtons = styled.div`
  max-width:100%;
  background-color: #515151;
  display: flex; 
  padding: 5px 0px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;



const TextAreaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  
  
`;

const InputArea = styled.div`
  width: auto;
  height: auto; 
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;

  
`;

const InputTitle = styled.textarea`
  width: 95%; 
  min-height: 100px; 
  border: none;
  padding: 10px;
  background-color: #515151;
  font-size: 20px;
  resize: none; 
  overflow: hidden; 
  box-sizing: border-box; 
  white-space: pre-wrap; 
  color: #fff;
`;

const InputDescricao = styled.textarea`
  width: 100%; 
  height: 200px; 
  border: 1px solid #ccc;
  border-radius: 1px;
  padding: 10px 10px 00px 20px;
  background-color: #5d5d5d;
  resize: none; /* Desabilitar o redimensionamento manual */
  box-sizing: border-box; /* Incluir o padding e a borda na largura total */
  overflow: auto; /* Permitir a rolagem automática */
  white-space: pre-wrap; /* Manter quebras de linha e espaços */
  color: #fff;
`;


const Input = styled.input`
  width: 130px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 1px;
  padding: 0px 10px;
  background-color: #5d5d5d;
  font-size: 16px;
  color: #fff;
`;

const Label = styled.label`
  color: #fff;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  margin: 0px 5px;
  padding: 0px 0px 0px 10px; 


`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  width: 150px;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border: 1px solid #e8eaeb;
  background-color: #e8eaeb;
  height: 42px;
  font-weight: bold;
  margin: 5px;
  border-radius: 5px;
  transition: transform 0.1s;
  gap: 10px;
  background: rgba(255, 255, 255, 0.1); /* Fundo translúcido */
  border: 1px solid rgba(255, 255, 255, 0.9); /* Borda translúcida */
  border-radius: 10px; /* Bordas arredondadas */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s;
  color: #fff;
  &:hover {
    transform: scale(1.1);
  }
`;

const A = styled.a`
  color: black;
  text-decoration: none;
  padding: 0px 0px 0px 10px; 
  margin: 0px 0px 0px 10px; 
  color: #fff;
`;



export const Td = styled.td`
  font-size: 14px;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;

`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 05px; 
`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
  color: #fff;
`;

const Hr = styled.div`
  display: flex;
  align-items: center; /* Alinha ao centro verticalmente */
  width: 95%; /* Largura total */
  height: 1px; /* Altura da linha */
  background-color: #ccc; /* Cor da linha */
  justify-content: center;
  margin-left: 2%;
`;

const Form = ({ urlApi, planos, setOnEdit, onEdit, fetchCheckbox, modalInfTarefa, infTarefa, card, usuarios }) => {
  const ref = useRef();

  useEffect(() => {
    if (infTarefa) {
      const user = ref.current;
      user.descricao_check.value = infTarefa.descricao_check;
      user.data_criacao.value = infTarefa.data_criacao;
      user.data_conclusao.value = infTarefa.data_conclusao;
      user.resposta_check.value = infTarefa.resposta_check;
      user.meta_conclusao.value = infTarefa.meta_conclusao;
      setImageUrl(infTarefa.id_check);
      setImageName(infTarefa.image);
    }

  }, [infTarefa]);



  const [validarBotao, setValidarBotao] = useState(false)
  useEffect(() => {
    const desativandoBotao = card.find(v => v.id_card === infTarefa.id_card);
    const isButtonDisabled = desativandoBotao?.id_status === 3; // Agora você pode acessar id_status corretamente
    setValidarBotao(isButtonDisabled)

  }, [infTarefa]);



  //Token da api
  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id');
  const usuario = localStorage.getItem('usuario');




  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = ref.current;


    const resposta_checkValue = user.resposta_check.value;

    // Verificação se o campo foi preenchido
    if (!resposta_checkValue) {
      return toast.warn("Preencha todos os campos!");
    }

    try {
      const { data } = await axios.put(`${urlApi}/gerenciamento-checkbox/resposta/${infTarefa.id_check}`, {
        resposta_check: resposta_checkValue,
        image: nameArquivo,
      }, { headers: { Authorization: `Bearer ${token}` } });

      // Feedback ao usuário e manipulação adicional após a resposta da API
      setTimeout(() => {
        modalInfTarefa();  // Exibição do modal ou informação adicional após o envio
      }, 1000);

    } catch (error) {
      // Tratamento de erros da requisição
      if (error.response) {
        const errorMessage = error.response.data.error || "Erro desconhecido";
        const details = error.response.data.details;
        const dbError = error.response.data.dbError;

        console.error("Error:", errorMessage);
        console.error("Details:", details);
        console.error("Database Error:", dbError);

        toast.error("Erro ao salvar os dados: " + errorMessage);
      } else {
        console.error("Erro de rede ou no servidor:", error.message);
        toast.error("Erro ao salvar os dados. Por favor, tente novamente.");
      }
    } finally {
      // Ações a serem executadas independentemente do sucesso ou falha da requisição
      fetchCheckbox();  // Atualização do estado após a operação
      setOnEdit(null);  // Limpeza do estado de edição
    }
  };


  //renderizar image
  const [imageName, setImageName] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageUrl2, setImageUrl2] = useState(null);

  useEffect(() => {
    fetch(`${urlApi}/upload-gerenciamento/img-tarefa/${imageUrl}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        if (response.ok) {
          setImageUrl2(response.url);
          console.log(response)
        } else {
          console.error('Erro ao obter a imagem:', response.statusText);
          console.log(response)
        }
      })
      .catch((error) => {
        console.error('Erro na requisição:', error);
      });
  }, [imageUrl]);


  //Upload imagem
  const [message, setMensagem] = useState('');
  const [nameArquivo, setNameArquivo] = useState('');
  const uploadImageOnChange = async (e) => {
    const selectedImage = e.target.files[0];

    const formData = new FormData();
    formData.append('image', selectedImage);
    setNameArquivo(selectedImage.name);
    const headers = {
      'headers': {
        'Content-Type': 'multipart/form-data'
      }
    }

    try {
      const response = await axios.post(`${urlApi}/upload-gerenciamento/upload-image-tarefa`, formData, headers);
      setMensagem(response.data.message);
    } catch (err) {
      if (err.response) {
        setMensagem(err.response.data.message);
      } else {
        setMensagem("Erro: verifique o tipo de arquivo que está sendo anexado")
      }
    }
  };




  // Renderiza o login do usuário responsavel pela tarefa
  const usuarioResponsavel = infTarefa.id_responsavel_tarefa
    ? usuarios.find(user => user.id_usuario == infTarefa.id_responsavel_tarefa)
    : null;
  const loginResponsavel = usuarioResponsavel ? usuarioResponsavel.login : '';






  return (
    <div >
      <FormContainer>


        <DivContainer ref={ref} onSubmit={handleSubmit}>
          <TextAreaContainer>

            <InputTitle
              id="descricao_check"
              readOnly
              disabled
            />
          </TextAreaContainer>
          <Hr />
          <Div>



            <InputArea>
              <Label>Criado:</Label>
              <Input
                name="data_criacao"
                readOnly
                disabled
              />
            </InputArea>
            <InputArea>
              <Label>Responsável:</Label>
              <Input
                value={loginResponsavel}
                readOnly
                disabled
              />
            </InputArea>

          </Div>
          <Div>

            <InputArea>
              <Label>Concluido:</Label>
              <Input
                name="data_conclusao"
                readOnly
                disabled
              />
            </InputArea>

            <InputArea>
              <Label>Entrega:</Label>
              <Input
                name="meta_conclusao"
                readOnly
                disabled

              />
            </InputArea>

          </Div>

          <Hr />

          <Div>
            <TextAreaContainer>
              <Label>Descrição</Label>
              <InputDescricao id="resposta_check" name="msg"></InputDescricao>
            </TextAreaContainer>
          </Div>

          <Div>
            {imageUrl2 ? (
              <A href={imageUrl2} target="_blank" rel="noopener noreferrer">
                <p>Arquivo: {imageName}</p>
              </A>
            ) : (
              <A>Nenhum arquivo anexado.</A>
            )}

          </Div>

          <Div>
            {id == infTarefa.id_responsavel_tarefa && (
              <form>
                <Label>Anexo:</Label>
                {message ? <p>{message}</p> : ""}
                <input type='file' name='image' onChange={uploadImageOnChange} />

              </form>
            )}

          </Div>



        </DivContainer>
        <FormContainer>
          <DivButtons>
            {infTarefa.data_conclusao === null && (
              <>
                {!validarBotao && id == infTarefa.id_responsavel_tarefa && (
                  <div>
                    <Button
                      title="Salvar"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      <ImCheckmark color={"#03A64A"} size={20} /> Salvar resposta
                    </Button>
                  </div>
                )}
              </>
            )}


          </DivButtons>
        </FormContainer>
      </FormContainer>
      <style jsx global>{`
      .swal2-container.custom-swal-container {
        z-index: 9999; 
      }
    `}</style>
    </div>

  );
};

export default Form;


