import React, { useState, useEffect } from "react";
import axios from 'axios';

import { useUsuariosHook } from '../../../hook/getUsuarios.js';

import GlobalStyle from "../../../styles/global.js";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import Image from './components/image.js';
import { toast, ToastContainer } from "react-toastify";


const Geral = styled.div`
`;
const Div = styled.div`
`;


function App() {

  //----------------------------//
  //Ip's da api e do front
  //const urlFront = "http://192.168.100.103:3050";
  //const urlApi = "http://192.168.100.103:8850/api";
  //const urlLogin = "http://192.168.100.103:3050/login";
  const urlFront = "";
  const urlApi = "/api";
  const urlLogin = "/login";

  //----------------------------//

  //Token da api
  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id');


  //Verifica o token do usuário
  const getToken = async () => {
    try {
      const res = await axios.get(`${urlApi}/login/token`, { headers: { Authorization: `Bearer ${token}` } });
    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}/login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }
  };



  useEffect(() => {
    getToken();

  }, [token])


  //----------------------------//

  //Retorna os hook
  const { usuarios } = useUsuariosHook();


  //----------------------------//

  // Verifica se o usuário é admin no portal
  const [tipoUsuarioIndicadores, setTipoUsuarioIndicadores] = useState('');
  useEffect(() => {
    const usuarioFiltrado = usuarios.find(usuario => usuario.id_usuario == id);
    if (usuarioFiltrado) {
      setTipoUsuarioIndicadores(usuarioFiltrado.tipo_indicadores);

    }
  }, [id, usuarios]);


  // Verifica se o usuário é admin no plano de ação
  const [tipoUsuarioPlano, setTipoUsuarioPlano] = useState('');
  useEffect(() => {
    const usuarioFiltrado = usuarios.find(usuario => usuario.id_usuario == id);
    if (usuarioFiltrado) {
      setTipoUsuarioPlano(usuarioFiltrado.tipo_plano);

    }
  }, [id, usuarios]);


  // Verifica se o usuário é admin no plano de ação
  const [tipoUsuarioPortal, setTipoUsuarioPortal] = useState('');
  useEffect(() => {
    const usuarioFiltrado = usuarios.find(usuario => usuario.id_usuario == id);
    if (usuarioFiltrado) {
      setTipoUsuarioPortal(usuarioFiltrado.tipo_portal);

    }
  }, [id, usuarios]);




  return (
    <>
      <Geral>
        <Div>
          <Image tipoUsuarioIndicadores={tipoUsuarioIndicadores} tipoUsuarioPlano={tipoUsuarioPlano} urlFront={urlFront} tipoUsuarioPortal={tipoUsuarioPortal} urlLogin={urlLogin}/>
        </Div>
        <GlobalStyle />
      </Geral>
      <ToastContainer autoClose={2500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
    </>
  );
}

export default App;
