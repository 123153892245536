import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ImCheckmark, ImLoop2 } from "react-icons/im";
import Swal from "sweetalert2";

const FormContainer = styled.form`
  width: 100%;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 0 auto; 
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

const DivGeral = styled.form`
  background-color: #fff;
  padding: 20px 20px;
  flex-direction: row;
`;

const Div = styled.td`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const InputArea = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;

  select {
    width: 220px;
    padding: 0 10px;
    border: 1px solid #bbb;
    border-radius: 1px;
    height: 37px;
  }
`;

const Input = styled.input`
  width: 200px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  
`;

const InputName = styled.input`
  width: 320px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;
`;

const Label = styled.label`
  color: #4f4f4f;
  font-weight: bold;
  font-size: 17px;  
`;

const ButtonContainer = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  margin: 1px 1px 1px 1px;
`;

const ButtonLimpar = styled.button`
  padding-bottom: 10px;
  cursor: pointer;
  border: none;
  background-color:   #fff;
  font-weight: bold;
  font-size: 17px;
  margin: 8px;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.1);
  }
`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  background-color:   #fff;
  font-weight: bold;
  font-size: 17px;
  margin: 8px;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.1);
  }
`;

const Div2 = styled.td`
  display: flex;
  flex-direction:column;

`;

export const Tr = styled.tr`
  text-align: center;
`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};

  select {
    margin-right: 5px;
  }
 
`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;

`;

const Form = ({ onEdit, setOnEdit, urlApi, fetchUsuarios }) => {


  const [senha, setSenha] = useState(null);
  const [repetirSenha, setRepetirSenha] = useState(null);


  const ref = useRef(null);


  //onEdit que vem do Grid para ser editado
  useEffect(() => {
    if (onEdit) {
      const user = ref.current;

      user.login.value = onEdit.login;
      user.tipo_portal.value = onEdit.tipo_portal;
      user.tipo_indicadores.value = onEdit.tipo_indicadores;
      user.tipo_plano.value = onEdit.tipo_plano;
      user.nome.value = onEdit.nome;
      user.email.value = onEdit.email;
    };

  }, [onEdit]);


  // tokens
  const token = localStorage.getItem('token');
  const usuario = localStorage.getItem('usuario');


  //popUp que confirma a operação
  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Deseja confirmar a ação?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container',
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };


  const saveLog = async (login, descricao) => {
    try {
      const logData = {
        login,
        descricao,
      };

      const response = await fetch(`${urlApi}/log`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(logData),
      });

      if (!response.ok) {
        throw new Error('Failed to save log');
      }
    } catch (error) {
      console.error('Erro ao salvar log:', error);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = ref.current;

    const loginValue = user.login.value;
    const senhaValue = user.senha.value;
    const nomeValue = user.nome.value;
    const repetirSenhaValue = user.repetirSenha.value;
    const tipoUsuarioValue = user.tipo_indicadores.value;
    const tipoPortalValue = user.tipo_portal.value;
    const tipoPlanoValue = user.tipo_plano.value;
    const emailValue = user.email.value;

    
    if (!loginValue || !tipoUsuarioValue || !tipoPortalValue || !nomeValue || !emailValue) {
      return toast.warn("Preencha todos os campos!");
    }

    if (senhaValue !== repetirSenhaValue) {
      return toast.error("As senhas não coincidem!");
    }

    const userConfirmed = await showPopUp();
    if (!userConfirmed) {
      return;
    }

    try {
      if (onEdit) {
        await axios
          .put(`${urlApi}/portal/${onEdit.id_usuario}`, {
            login: loginValue,
            tipo_indicadores: tipoUsuarioValue,
            tipo_portal: tipoPortalValue,
            tipo_plano: tipoPlanoValue,
            nome: nomeValue,
            email: emailValue,
          }, { headers: { Authorization: `Bearer ${token}` } })
          .then(async ({ data }) => {

            toast.success(data);
            console.log(senha);
            if (senha !== null) {
              await handleSubmitSenha(); // Remova o e.preventDefault() de handleSubmitSenha
            };

            await saveLog(usuario, `Usuário ${usuario} editou o login ${loginValue}`);
          })
          .catch(async (error) => {
            if (error.response) {
              const errorMessage = error.response.data.error || 'Erro desconhecido';
              console.log("Error:", errorMessage);
              toast.error("Erro ao editar: " + errorMessage);
              await saveLog(usuario, `Erro ao editar o login ${loginValue} pelo usuário ${usuario}: ${errorMessage}`);
            } else {
              console.error('Erro inesperado:', error);
              toast.error('Erro inesperado.');
              await saveLog(usuario, `Erro inesperado ao editar o login ${loginValue} pelo usuário ${usuario}`);
            }
          });
      } else {
        await axios
          .post(`${urlApi}/portal`, {
            login: loginValue,
            senha: senhaValue,
            tipo_indicadores: tipoUsuarioValue,
            tipo_portal: tipoPortalValue,
            tipo_plano: tipoPlanoValue,
            nome: nomeValue,
            email: emailValue,
          }, { headers: { Authorization: `Bearer ${token}` } })
          .then(async ({ data }) => {
            toast.success(data);

            await saveLog(usuario, `Usuário ${usuario} adicionou o login ${loginValue}`);
          })
          .catch(async (error) => {
            if (error.response) {
              const errorMessage = error.response.data.error || 'Erro desconhecido';
              console.log("Error:", errorMessage);
              toast.error("Erro ao adicionar: " + errorMessage);
              await saveLog(usuario, `Erro ao adicionar o login ${loginValue} pelo usuário ${usuario}: ${errorMessage}`);
            } else {
              console.error('Erro inesperado:', error);
              toast.error('Erro inesperado.');
              await saveLog(usuario, `Erro inesperado ao adicionar o login ${loginValue} pelo usuário ${usuario}`);
            }
          });
      }
    } catch (error) {
      console.error('Erro inesperado:', error);
      toast.error('Erro inesperado.');
      await saveLog(usuario, `Erro inesperado ao processar o login ${loginValue} pelo usuário ${usuario}`);
    }


    fetchUsuarios();
    setOnEdit(null);

    user.login.value = "";
    user.tipo_indicadores.value = "";
    user.tipo_portal.value = "";
    user.tipo_plano.value = "";
    user.nome.value = "";
    user.email.value = "";

  };

  const handleSubmitSenha = async (e) => {
    const user = ref.current;

    const senhaValue = user.senha.value;
    const repetirSenhaValue = user.repetirSenha.value;

    if (senhaValue !== repetirSenhaValue) {
      return toast.error("As senhas não coincidem!");
    }


    try {
      if (onEdit) {
        await axios
          .put(`${urlApi}/portal/senha/${onEdit.id_usuario}`, {
            senha: senhaValue,
          }, { headers: { Authorization: `Bearer ${token}` } })
          
          .catch(async (error) => {
            if (error.response) {
              const errorMessage = error.response.data.error || 'Erro desconhecido';
              const statusCode = error.response.status;
              const url = error.config.url;

              console.log("Erro na atualização da senha:", {
                mensagem: errorMessage,
                statusCode: statusCode,
                url: url,
                response: error.response.data,
              });

              toast.error(`Erro ao editar a senha: ${errorMessage} (Status: ${statusCode})`);
            } else if (error.request) {
              // Erro relacionado à requisição em si (por exemplo, sem resposta do servidor)
              console.error('Erro de requisição:', {
                request: error.request,
                url: error.config.url,
              });
              toast.error('Erro ao enviar a requisição. Verifique sua conexão.');
            } else {
              // Outros tipos de erro (por exemplo, erro na configuração)
              console.error('Erro inesperado:', {
                mensagem: error.message,
                config: error.config,
              });
              toast.error('Erro inesperado. Verifique os detalhes no console.');
            }
          });
      }
    } catch (error) {
      // Captura erros inesperados
      console.error('Erro inesperado durante a tentativa de editar a senha:', error);
      toast.error('Erro inesperado. Verifique os detalhes no console.');
    }

    fetchUsuarios();
    setOnEdit(null);
    setSenha('');
    setRepetirSenha('')

  };





  return (
    <>
      <FormContainer onSubmit={handleSubmit}>
        <DivGeral ref={ref}>
          <Div>
            <Div2>
              <InputArea>
                <Label>Login:</Label>
                <Input name="login" type="text" placeholder="Digite o login" />
              </InputArea>

            </Div2>

            <Div2>
              <InputArea>
                <Label>Senha:</Label>
                <Input name="senha" type="password" value={senha} onChange={(e) => setSenha(e.target.value)} placeholder="Digite sua senha" />
              </InputArea>

            </Div2>

            <Div2>
              <InputArea>
                <Label>Repetir a senha:</Label>
                <Input name="repetirSenha" type="password" value={repetirSenha} onChange={(e) => setRepetirSenha(e.target.value)} placeholder="Digite sua senha novamente"
                />
              </InputArea>


            </Div2>
              
          </Div>
          <Div>
            <Div2>
              <InputArea>
                <Label>Nome Completo:</Label>
                <InputName name="nome" type="text" placeholder="Digite seu nome" />
              </InputArea>

            </Div2>

            <Div2>
              <InputArea>
                <Label>Email:</Label>
                <InputName name="email" type="text" placeholder="Digite seu nome" />
              </InputArea>

            </Div2>
          </Div>

          <Div>
            <Div2>
              <InputArea>
                <Label>Portal:</Label>
                <select name="tipo_portal" >
                  <option value="" disabled selected>Tipo de usuário</option>
                  <option value="ADMIN">Administrador</option>
                  <option value="GESTOR">Gestor</option>
                  <option value="USER">Usuário</option>
                </select>
              </InputArea>
            </Div2>

            <Div2>
              <InputArea>
                <Label>Indicadores:</Label>
                <select name="tipo_indicadores" >
                  <option value="" disabled selected>Tipo de usuário</option>
                  <option value="ADMIN">Administrador</option>
                  <option value="GESTOR">Gestor</option>
                  {/*<option value="TREINADOR">Treinador</option>*/}
                  <option value="USER">Usuário</option>
                  <option value="NAO">Não pode acessar</option>
                </select>
              </InputArea>
            </Div2>

            <Div2>
              <InputArea>
                <Label>Plano de Ação:</Label>
                <select name="tipo_plano" disabled>
                  <option value="" disabled selected>Tipo de usuário</option>
                  <option value="SIM">Sim, pode acessar</option>
                  <option value="NAO">Não pode acessar</option>
                </select>
              </InputArea>
            </Div2>
          </Div>
        </DivGeral>

        <Div>
          <Div2>
            <ButtonContainer>
              <ButtonLimpar title="Atualizar" type="button" onClick={() => window.location.reload()}>
                <ImLoop2 color={"gold"} /> Atualizar
              </ButtonLimpar>
              <Button title="Salvar" type="submit" >
                <><ImCheckmark color={"green"} /> Salvar </>
              </Button>
            </ButtonContainer>
          </Div2>
        </Div>
        
      </FormContainer>

    </>
  );
};

export default Form;


